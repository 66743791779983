import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    emailError: false,
  },
});

class EditClientDialog extends Component {


  handleEditClose = () => {
      let client = {
        client_id: this.props.editClient.id,
        client_name: this.props.editClient.name,
        email: this.props.editClient.email,
        address_1: this.props.editClient.address_1,
        address_2: this.props.editClient.address_2,
        city: this.props.editClient.city,
        state: this.props.editClient.state,
        zip: this.props.editClient.zip,
        phone: this.props.editClient.phone
      };
      this.props.onClose(client);
  };

  handleEdit = (name) => (event) => {
    this.props.editClient[name] = event.target.value
  };

  handleCancel = () => {
    this.props.onClose();
  }

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="edit-group-dialog"
      >
        <DialogTitle id="edit-group-dialog">Edit a Client</DialogTitle>
        <DialogContent>
          <Typography>Client Id: {this.props.editClient ? this.props.editClient.id : ""}</Typography>
          <TextField
            id="edit-client-name"
            label="Name"
            className={classes.textField}
            onChange={this.handleEdit("name")}
            margin="normal"
            name="client.name"
            defaultValue={this.props.editClient ? this.props.editClient.name : ""}
          />
          <TextField
            id="edit-client-email"
            label="Email"
            className={classes.textField}
            onChange={this.handleEdit("email")}
            margin="normal"
            name="client.email"
            defaultValue={this.props.editClient ? this.props.editClient.email : ""}
          />
          <TextField
            id="edit-client-address_1"
            label="Address"
            className={classes.textField}
            onChange={this.handleEdit("address_1")}
            margin="normal"
            name="client.address_1"
            defaultValue={this.props.editClient ? this.props.editClient.address_1 : ""}
          />
          <TextField
            id="edit-client-address_2"
            label="Address"
            className={classes.textField}
            onChange={this.handleEdit("address_2")}
            margin="normal"
            name="client.address_2"
            defaultValue={this.props.editClient ? this.props.editClient.address_2 : ""}
          />
          <TextField
            id="edit-client-city"
            label="City"
            className={classes.textField}
            onChange={this.handleEdit("city")}
            margin="normal"
            name="client.city"
            defaultValue={this.props.editClient ? this.props.editClient.city : ""}
          />
          <TextField
            id="edit-client-state"
            label="State"
            className={classes.textField}
            onChange={this.handleEdit("state")}
            margin="normal"
            name="client.state"
            defaultValue={this.props.editClient ? this.props.editClient.state : ""}
          />
          <TextField
            id="edit-client-zip"
            label="Zip"
            className={classes.textField}
            onChange={this.handleEdit("zip")}
            margin="normal"
            name="client.zip"
            defaultValue={this.props.editClient ? this.props.editClient.zip : ""}
          />
          <TextField
            id="edit-client-phone"
            label="Phone"
            className={classes.textField}
            onChange={this.handleEdit("phone")}
            margin="normal"
            name="client.phone"
            defaultValue={this.props.editClient ? this.props.editClient.phone : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleEditClose} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditClientDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(EditClientDialog);
