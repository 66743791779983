import React, { Component } from "react";
import SelectDownloadDialog from "./SelectDownloadDialog";
import { Button } from "@material-ui/core";
import MassImportTable from "./MassImportTable";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CSVToArray from "./csvParser";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import CsvTemplate from "../../TemplateFiles/ImportTemplate.csv";
import { CSVReader } from "react-papaparse";
import LoadingOverlay from "react-loading-overlay";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;
const buttonRef = React.createRef();

class MassImport extends Component {
  constructor(props) {
    super();

    this.state = {
      contacts: [],
      displayModal: false,
      successfullyImportedCount: 0,
      optoutContacts: 0,
      uploadLimit: false,
      uploadUrl: "",
      isLoading: false,
    };
  }

  insertContacts = () => {
    if (this.state.contacts.length < 1) {
      return;
    }
    let data = {
      group_id: this.props.match.params.id,
      contacts: this.state.contacts.filter(
        (contact) => contact.errors.length == 0
      ),
    };
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/create/bulk", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        let contentsLength = response.contacts[0].insert_bulk_contacts
          ? response.contacts[0].insert_bulk_contacts
          : 0;
        let optoutContactAmount =
          response.optouts[0].get_optout_contacts.length;
        this.setState({
          contacts: [],
          successfullyImportedCount: contentsLength,
          optoutContacts: optoutContactAmount,
          displayModal: true,
        });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  getUploadUrl = () => {
    let data = {
      groupId: this.props.match.params.id,
    };
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/uploadurl", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((response) => {
        this.setState({ uploadUrl: response });
        this.uploadFile();
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "text/*",
        // Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(this.state.contacts),
    })
      .then((response) => {
        if (response.ok) {
          this.setState({
            uploadUrl: "",
            isLoading: false,
            displayModal: true,
            contacts: [],
          });
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  handleOnFileLoad = (data) => {
    this.setState({ contacts: data });
    // if (data.length <= 30000) {
    //   data = data.filter((item) => item.errors.length === 0);
    //   if (this.state.contacts.length > 0) {
    //     this.setState({
    //       contacts: data.concat(this.state.contacts),
    //       uploadLimit: false,
    //     });
    //   } else {
    //     this.setState({ contacts: data, uploadLimit: false });
    //   }
    // } else {
    //   this.setState({ uploadLimit: true });
    // }
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  closeModal = () => {
    this.setState({ displayModal: false, successfullyImportedCount: null });
  };

  render() {
    let contactArea;
    if (this.state.contacts.length !== 0 && this.state.uploadLimit === false) {
      contactArea = <MassImportTable rows={this.state.contacts} />;
    } else if (this.state.uploadLimit) {
      contactArea = (
        <Paper>
          <Typography variant="h5" component="h3">
            Unable to upload more than 30,000 contacts at one time.
          </Typography>
        </Paper>
      );
    } else {
      contactArea = (
        <Typography variant="h5" component="h3" align="center">
          Upload contacts to preview, save to confirm upload
        </Typography>
      );
    }

    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner={true}
        text="Please wait for your upload confirmation before leaving the page! If it takes longer than 10 minutes contact your admin."
      >
        <div>
          <Dialog open={this.state.displayModal}>
            <Card>
              <CardContent>
                <h3>
                  Contact upload has been queued. You will receive an email when
                  your contacts have been uploaded.
                </h3>
              </CardContent>
              <CardActions>
                <Button onClick={this.closeModal}>OK</Button>
              </CardActions>
            </Card>
          </Dialog>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <h1>Mass Import</h1>
            </Grid>
          </Grid>

          <Grid container spacing={16}>
            <Grid item xs={1} />
            <Grid item xs={3}>
              <CSVReader
                ref={buttonRef}
                onFileLoad={this.handleOnFileLoad}
                onError={this.handleOnError}
                config={{ header: true }}
                noClick
                noDrag
              >
                {({ file }) => (
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.isLoading}
                      onClick={this.handleOpenDialog}
                      style={{ margin: "10px" }}
                    >
                      Upload File
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      aria-label="get_app"
                      download={"ImportTemlate.csv"}
                      href={CsvTemplate}
                      disabled={this.state.isLoading}
                      style={{ margin: "10px" }}
                    >
                      Download Template
                    </Button>
                  </div>
                )}
              </CSVReader>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={3}>
              <Button
                onClick={this.getUploadUrl}
                variant="contained"
                color="primary"
                disabled={
                  this.state.uploadLimit ||
                  this.state.isLoading ||
                  this.state.contacts.length < 1
                }
                style={{ margin: "10px" }}
              >
                Save Contacts
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.props.history.goBack}
                disabled={this.state.isLoading}
                style={{ margin: "10px" }}
              >
                Back
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={16}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {contactArea}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </div>
      </LoadingOverlay>
    );
  }
}

export default MassImport;
