import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/moment";
import { Grid } from "@material-ui/core";
import AdditionalScripts from "./AdditionalScripts";
import KeywordAutoResponses from "./KeywordAutoResponse";
import MMS from "./MMS";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import LoadingOverlay from "react-loading-overlay";
import Tooltip from "@material-ui/core/Tooltip";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px",
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px",
  },
});

var endDateTime = new Date();
endDateTime.setDate(endDateTime.getDate() + 3);

class ManageGoal extends Component {
  state = {
    goalname: "",
    goaldescription: "",
    selectedDate: new Date(),
    selectedEndDate: endDateTime,
    invalidDateRange: false,
    defaultScripts: [
      { title: "inital script", body: "", type: "init" },
      { title: "yes script", body: "", type: "yes" },
      { title: "no script", body: "", type: "no" },
    ],
    additionalScripts: [],
    availableDynamicFields: [],
    isLoading: false,
    isUploading: false,
    setGSM: true,
    checkedMms: false,
    uploadUrlResponse: {},
    files: [],
    is10dlc: false,
    keywordAutoResponses: []
  };

  componentDidMount() {
    this.setState({
      newContact: "",
      existingContact: "",
      goalname: "",
      goaldescription: "",
    });

    this.getContactDataNames();
  }

  getContactDataNames = () => {
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN +
        `/contact/contactdatanames/${this.props.groupId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => this.setState({ availableDynamicFields: response }))
      .catch((error) => this.setState({ message: error.message }));
  };

  createGoal = (goal) => {
    this.setState({ isLoading: true });
    return fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(goal),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleDefaultScriptChange = (name) => (event) => {
    var scriptsHold = this.state.defaultScripts;
    var location = scriptsHold.findIndex((item) => item.title === name);
    scriptsHold[location].body = event.target.value;
    this.setState({ defaultScripts: scriptsHold });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    this.validateDates(date, this.state.selectedEndDate);
  };

  handleEndDateChange = (date) => {
    this.setState({ selectedEndDate: date });
    this.validateDates(this.state.selectedDate, date);
  };

  validateDates = (start, end) => {
    if (start >= end) {
      this.setState({ invalidDateRange: true });
    } else {
      this.setState({ invalidDateRange: false });
    }
  };

  calcSms = (count) => {
    if (count === 0) {
      return "0/0";
    }
    let hold =
      count +
      "/" +
      (Math.floor(count / 160) === 0 ? 1 : Math.ceil(count / 160));
    return hold;
  };

  handleIs10dlc = () => (event) => {
    this.setState({ is10dlc: event.target.checked });
  };

  handleSave = () => {
    var goalChange = {
      groupId: this.props.groupId,
      name: this.state.goalname,
      description: this.state.goaldescription,
      startTime: this.state.selectedDate,
      endTime: this.state.selectedEndDate,
      defaultScripts: this.state.defaultScripts,
      additionalScripts: this.state.additionalScripts,
      keywordAutoResponses: this.state.keywordAutoResponses,
      setGSM: this.state.setGSM,
      bucket: this.state.uploadUrlResponse.bucket,
      key: this.state.uploadUrlResponse.key,
      is10dlc: this.state.is10dlc,
    };
    this.createGoal(goalChange).then(() => {
      this.props.cancelManage();
    });
  };

  createScript = () => {
    var addScript = [...this.state.additionalScripts];
    addScript.push({
      id: this.state.additionalScripts.length,
      title: "",
      body: "",
      type: "additional",
    });
    this.setState({ additionalScripts: addScript });
  };

  createKeywordAutoResponse = () => {
    var addKeyword = [...this.state.keywordAutoResponses];
    addKeyword.push({
      id: this.state.keywordAutoResponses.length,
      title: "",
      keyword: "",
      body: "",
      matchRule: "contains",
      tier: 1
    });
    this.setState({ keywordAutoResponses: addKeyword });
  };

  removeScript = (index) => {
    var removeScript = [...this.state.additionalScripts];
    removeScript.splice(index, 1);
    this.setState({ additionalScripts: removeScript });
  };

  removeKeyword = (index) => {
    var removeKeyword = [...this.state.keywordAutoResponses];
    removeKeyword.splice(index, 1);
    this.setState({ keywordAutoResponses: removeKeyword });
  };

  handleTitleChange = (script) => (event) => {
    var titleChange = [...this.state.additionalScripts];
    var location = titleChange.findIndex((item) => item.id === script.id);
    titleChange[location].title = event.target.value;
    this.setState({ additionalScripts: titleChange });
  };


  handleKeywordTitleChange = (script) => (event) => {
    var titleChange = [...this.state.keywordAutoResponses];
    var location = titleChange.findIndex((item) => item.id === script.id);
    titleChange[location].title = event.target.value;
    this.setState({ keywordAutoResponses: titleChange });
  };

  handleKeywordChange = (script) => (event) => {
    var keywordChange = [...this.state.keywordAutoResponses];
    var location = keywordChange.findIndex((item) => item.id === script.id);
    keywordChange[location].keyword = event.target.value;
    this.setState({ keywordAutoResponses: keywordChange });
  };

  handleBodyChange = (script) => (event) => {
    var bodyChange = [...this.state.additionalScripts];
    var location = bodyChange.findIndex((item) => item.id === script.id);
    bodyChange[location].body = event.target.value;
    this.setState({ additionalScripts: bodyChange });
  };

  handleKeywordBodyChange = (script) => (event) => {
    var bodyChange = [...this.state.keywordAutoResponses];
    var location = bodyChange.findIndex((item) => item.id === script.id);
    bodyChange[location].body = event.target.value;
    this.setState({ keywordAutoResponses: bodyChange });
  };

  handleKeywordMatchRuleChange = (script) => (event) => {
    var ruleChange = [...this.state.keywordAutoResponses];
    var location = ruleChange.findIndex((item) => item.id === script.id);
    ruleChange[location].matchRule = event.target.value;
    this.setState({ keywordAutoResponses: ruleChange });
  };

  handleKeywordtierChange = (script) => (event) => {
    var ruleChange = [...this.state.keywordAutoResponses];
    var location = ruleChange.findIndex((item) => item.id === script.id);
    ruleChange[location].tier = event.target.value;
    this.setState({ keywordAutoResponses: ruleChange });
  };

  handleMmsChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  upload = (files) => {
    if (files.length !== 1) {
      this.setState({ files: files });
      if (files.length === 0) {
        this.setState({ uploadUrlResponse: {} });
      }
      return;
    }
    this.setState({ isUploading: true, files: files });
    const file = files[0].file;
    const data = {
      groupId: this.props.groupId,
      fileName: file.name,
      fileType: file.type,
    };
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/mms/uploadurl", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((response) => {
        this.setState({ uploadUrlResponse: response });
        this.uploadFile(file, response);
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  uploadFile = (file) => {
    fetch(this.state.uploadUrlResponse.url, {
      method: "PUT",
      headers: {
        "Content-Type": `${file.type}`,
      },
      body: file,
    })
      .then((response) => {
        if (response.ok) {
          this.setState({ isUploading: false });
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  onFileDelete = (id) => {
    this.setState({
      files: this.state.files.filter((x) => x.id !== id),
      uploadUrlResponse: {},
    });
  };

  render() {
    const { classes, disable10dlc } = this.props;
    const { selectedDate, selectedEndDate, checkedMms, files, is10dlc } =
      this.state;
    const disabled10dlctooltip = disable10dlc
      ? "Client does not have a registered 10 DLC phone number. Add registered number to client to enable."
      : "";
    let goalPage = <div></div>;
    if (this.state.isLoading) {
      goalPage = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    } else {
      goalPage = (
        <>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <h1>Goal Information</h1>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={this.props.cancelManage}
                variant="outlined"
                className={classes.buttonRight}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleSave}
                variant="contained"
                color="primary"
                className={classes.buttonRight}
                disabled={this.state.isLoading || this.state.invalidDateRange}
              >
                Save Goal
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={16} justify="center">
            <Grid item xs={6}>
              <TextField
                id="outlined-full-width"
                label="Goal Name"
                style={{ margin: 8 }}
                placeholder="Insert Goal Name Here (Ex: Volunteer Recruitment)"
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange("goalname")}
              />
              <TextField
                id="outlined-full-width"
                label="Description"
                style={{ margin: 8 }}
                placeholder="Write a Short Description of Goal Here (Ex: Volunteer outreach week of 10/4)"
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange("goaldescription")}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <div
                style={this.state.invalidDateRange ? {} : { display: "None" }}
              >
                <Typography style={{ color: "red" }}>
                  End date must be after start date
                </Typography>
              </div>
              <Typography>Start Date:</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  margin="normal"
                  label="Date picker"
                  value={selectedDate}
                  onChange={this.handleDateChange}
                />
                <TimePicker
                  margin="normal"
                  label="Time picker"
                  value={selectedDate}
                  onChange={this.handleDateChange}
                />
              </MuiPickersUtilsProvider>
              <Typography>End Date:</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  margin="normal"
                  label="Date picker"
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
                <TimePicker
                  margin="normal"
                  label="Time picker"
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <FormGroup row>
            <Tooltip title={disabled10dlctooltip}>
              <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={is10dlc}
                      disabled={disable10dlc}
                      onChange={this.handleIs10dlc("is10dlc")}
                      value="is10dlc"
                      color="primary"
                    />
                  }
                  label="10DLC"
                />
              </span>
            </Tooltip>
            <FormControlLabel
              control={
                <Switch
                  checked={checkedMms}
                  onChange={this.handleMmsChange("checkedMms")}
                  value="checkedMms"
                  color="primary"
                />
              }
              label="MMS"
            />
          </FormGroup>
          {checkedMms && (
            <MMS
              upload={this.upload}
              onFileDelete={this.onFileDelete}
              files={files}
            />
          )}
          <h1>Scripts</h1>
          <Grid container justify="space-evenly" spacing={32}>
            <Grid item xs={4}>
              <TextField
                id="filled-multiline-flexible-new-contacts"
                label="For New Contacts"
                multiline
                rowsMax="6"
                value={this.state.multiline}
                onChange={this.handleDefaultScriptChange("inital script")}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                helperText={this.calcSms(
                  this.state.defaultScripts[0].body.length
                )}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="filled-multiline-flexible-new-contacts"
                label="Yes Response"
                multiline
                rowsMax="6"
                value={this.state.multiline}
                onChange={this.handleDefaultScriptChange("yes script")}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                helperText={this.calcSms(
                  this.state.defaultScripts[1].body.length
                )}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="filled-multiline-flexible-new-contacts"
                label="No Response"
                multiline
                rowsMax="6"
                value={this.state.multiline}
                onChange={this.handleDefaultScriptChange("no script")}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                helperText={this.calcSms(
                  this.state.defaultScripts[2].body.length
                )}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <AdditionalScripts
            scripts={this.state.additionalScripts}
            handleBodyChange={this.handleBodyChange}
            handleTitleChange={this.handleTitleChange}
            removeScript={this.removeScript}
            createScript={this.createScript}
            isLoading={this.state.isLoading}
          />
          <KeywordAutoResponses
            scripts={this.state.keywordAutoResponses}
            handleKeywordBodyChange={this.handleKeywordBodyChange}
            handleKeywordTitleChange={this.handleKeywordTitleChange}
            handleKeywordChange={this.handleKeywordChange}
            handleKeywordMatchRuleChange={this.handleKeywordMatchRuleChange}
            removeKeyword={this.removeKeyword}
            createKeywordAutoResponse={this.createKeywordAutoResponse}
            handleKeywordtierChange={this.handleKeywordtierChange}
            isLoading={this.state.isLoading}
          />          
          <h1>Available Dynamic Fields</h1>
          <Grid container spacing={32}>
            <Grid item xs={4}>
              <List dense>
                {this.state.availableDynamicFields.map(
                  (dynamicField, index) => (
                    <ListItem>
                      <ListItemText primary={"{{" + dynamicField.name + "}}"} />
                    </ListItem>
                  )
                )}
              </List>
            </Grid>
          </Grid>          
        </>
      );
    }

    return (
      <div>
        {goalPage}
        <LoadingOverlay
          active={this.state.isUploading}
          spinner={true}
          text="Uploading..."
          styles={{
            overlay: {
              position: "fixed",
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px",
              zIndex: 800,
              textAlign: "center",
              display: "flex",
              opacity: 1,
              background: "rgba(0,0,0,0.7)",
            },
          }}
        ></LoadingOverlay>
      </div>
    );
  }
}

ManageGoal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageGoal);
