import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

class NpgVanIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            npgvanAuthToken: '',
            hasToken: false,
            npgConnectionId: -1,
            updateToken: false,
            selectedDatabaseConnectionId:-1,
            npgvanApplicationName: '',
            databaseConnections: []

        }
    }

    componentWillMount = () => {
        this.checkIntegrationToken();
        this.getNpgVanDatabaseConnections();
    }

    //check to see if they already have an auth token
    checkIntegrationToken = () => {
        this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/token/exist",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                }
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    if(response.length > 0){
                        this.setState({ isLoading: false, hasToken: true, npgConnectionId: response[0].id});
                    }else{
                        this.setState({isLoading: false, hasToken: false});
                    }               
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    getNpgVanDatabaseConnections = () => {
        this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/database/get",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                }
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    if(response.length > 0){
                        this.setState({ databaseConnections: response });
                    }               
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    createAuthToken = () => {
         this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/token/create",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    authToken: this.state.npgvanAuthToken,
                    applicationName: this.state.applicationName,
                    databaseConnectionId: this.state.selectedDatabaseConnectionId
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                   this.setState({hasToken: true, npgConnectionId: response[0].create_auth_token, npgvanAuthToken: '', isLoading: false});
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    updateAuthToken = () => {
        this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/token/update",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    npgConnectionId: this.state.npgConnectionId,
                    authToken: this.state.npgvanAuthToken,
                    applicationName: this.state.applicationName,
                    databaseConnectionId: this.state.selectedDatabaseConnectionId
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                   this.setState({npgvanAuthToken: '', hasToken: true, updateToken: false, npgConnectionId: response[0].auth_id, isLoading: false});
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    syncNPGVanData = () => {
        this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/sync",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                }
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    this.setState({ isLoading: false });
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    handleAuthToken = () => event => {
        this.setState({npgvanAuthToken: event.target.value});
    }

    handleSaveClick = () => {
        this.createAuthToken();
    }

    handleChangeClick=()=>{
        this.setState({updateToken: true});
    }

    handleUpdateClick = () => {
       this.updateAuthToken();
    }

    handleSyncClick = () => {
        this.syncNPGVanData();
    }

    handleApplicationName = () => event => {
        this.setState({applicationName: event.target.value});
    }

    handleDatabaseSelection = () => event => {
        this.setState({selectedDatabaseConnectionId: event.target.value});
    }


    render() {

        let authField = '';
        let authButton = '';
        let syncButton = '';
        let authMessage = '';
        let applicationName = '';
        let databaseConnection = '';
        if(this.state.isLoading){
            authField = (<CircularProgress />);

        }else if(this.state.hasToken && this.state.updateToken){
            authField = (<TextField id="outlined-basic" value={this.state.npgvanAuthToken} fullWidth label="Auth Token" variant="outlined"  onChange={this.handleAuthToken()} />);
            authButton = (<Button variant="contained" style={{marginRight: "20px"}} onClick={this.handleUpdateClick}>Update</Button>);
            syncButton = (<Button variant="contained" style={{marginRight: "20px"}} onClick={this.syncNPGVanData}>Sync</Button>);
            authMessage = '';
            applicationName = (<TextField id="outlined-basic" value={this.state.applicationName} fullWidth label="Application Name" variant="outlined"  onChange={this.handleApplicationName()} />);
            databaseConnection = (<FormControl variant="outlined">
            <InputLabel id="database-van">Database</InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={this.state.selectedDatabaseConnectionId}
              onChange={this.handleDatabaseSelection()}
              label="Database"
            >
              <MenuItem value="-1">
                None
              </MenuItem>
              {
                  this.state.databaseConnections.map((item,index) => (
                    <MenuItem key={0 + item.id + index} value={item.id}>
                    {item.database}
                  </MenuItem>
                  ))
              }
            </Select>
          </FormControl>);

        }else if(this.state.hasToken){
            authField = (<span>Organization already has an auth token. Feel free to change it.</span>);
            authButton = (<Button variant="contained" color='primary' style={{marginRight: "20px"}} onClick={this.handleChangeClick}>Change</Button>);
            syncButton = (<Button variant="contained" color='primary' style={{marginRight: "20px"}} onClick={this.handleSyncClick}>Sync</Button>);
            authMessage = '';
        }else{
            authField = (<TextField id="outlined-basic" value={this.state.npgvanAuthToken} fullWidth label="Auth Token" variant="outlined"  onChange={this.handleAuthToken()} />);
            authButton = (<Button variant="contained" onClick={this.handleSaveClick}>Save</Button>);
            applicationName = (<TextField id="outlined-basic" value={this.state.applicationName} fullWidth label="Application Name" variant="outlined"  onChange={this.handleApplicationName()} />);
            databaseConnection = (<FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">Database</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.selectedDatabaseConnectionId}
              onChange={this.handleDatabaseSelection()}
              label="Database"
            >
              <MenuItem value="-1">
                None
              </MenuItem>
              {
                  this.state.databaseConnections.map((item,index) => (
                    <MenuItem key={1 + item.id + index} value={item.id}>
                    {item.database}
                  </MenuItem>
                  ))
              }
            </Select>
          </FormControl>);
            syncButton = (<div></div>);
            authMessage = (<span>Please keep track of your auth token, you won't be able to get it later.</span>)
        }


        return (
            <Paper elevation={3} style={{padding: '25px'}}>
                <h2>NGP VAN</h2>
                <Grid container spacing={40}>
                    <Grid item xs={3}>
                        {authMessage}
                        {authField}
                    </Grid>
                    <Grid item xs={2}>
                        {applicationName}
                    </Grid>
                    <Grid item xs={2}>
                        {databaseConnection}
                    </Grid>
                    <Grid item xs={5}>
                        {authButton}
                        {syncButton}
                        <Button disabled={!this.state.hasToken} color='primary' variant="contained" href={"/integrations/npgvan/mapping"}>Setup Data Mappings</Button>  
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default NpgVanIntegration;