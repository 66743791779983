import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    color: grey[50],
  },
  navButton: {
    posistion: "relative",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class Nav extends Component {
  render() {
    const { classes } = this.props;
    const { isAuthenticated, logout, userIsInRoles } = this.props.auth;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar style={{paddingRight: '34px', paddingLeft: '34px'}}>
            {isAuthenticated() && userIsInRoles(["orgAdmin"]) && (
              <Button href="/" className={classes.navButton}>
                <Typography variant="h6" className={classes.grow}>
                  Groups
                </Typography>
              </Button>
            )}
            {isAuthenticated() && userIsInRoles(["orgAgent"]) && (
              <Button href="/goal/select" className={classes.navButton}>
                <Typography variant="h6" className={classes.grow}>
                  Goal Select
                </Typography>
              </Button>
            )}
            {isAuthenticated() && userIsInRoles(["orgAdmin"]) && (
              <Button href="/settings" className={classes.navButton}>
                <Typography variant="h6" className={classes.grow}>
                  Organization Settings
                </Typography>
              </Button>
            )}
            <Typography variant="h6" className={classes.grow}></Typography>
            <Button onClick={logout} color="inherit">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Nav);
