import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GroupTabs from "./GroupTabs";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`
  },
  groupListItem: {
    cursor: "pointer"
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`
  },
  column: {
    padding: theme.spacing.unit * 2
  },
  gridSpacing: {
    padding: "0px 24px 24px 24px"
  }
});

class GroupLanding extends Component {
  state = {
    groupName: ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    //get group details
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/getbyid/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`
      }
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(response => {
        this.setState({ groupName: response[0].name });
      })
      .catch(error => this.setState({ message: error.message }));
  }

  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;
    return (
      <div>
        <Grid container className={classes.gridSpacing}>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.title}>
              {this.state.groupName}
            </Typography>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={1}>
            <Link to={"/groupsettings/" + id}>
              <IconButton aria-label="Settings">
                <SettingsIcon fontSize="large" />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
        <GroupTabs
          group_id={this.props.match.params}
          auth={this.props.auth}
        />
      </div>
    );
  }
}

GroupLanding.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupLanding);
