import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    minWidth: 275,
    margin: "15px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  receivedMessage: {
    background: "lightBlue",
  },
  unread: {
    "& *": {
      color: "black",
      "font-weight": "bold",
    },
  },
};

class ConversationCard extends Component {
  render() {
    const {
      conversation,
      classes,
      selectConversation,
      markAsRead,
      markAsUnread,
      markAsArchived,
      tagAsWrongNumber,
      index,
      optoutClick,
    } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent
          className={!conversation.read ? classes.receivedMessage : ""}
        >
          <Typography
            className={(classes.title, classes.unread)}
            color="textSecondary"
            gutterBottom
          >
            Active Conversation
          </Typography>
          <Typography variant="h5" component="h2">
            Name:{" "}
            {conversation.contact_first_name +
              " " +
              conversation.contact_last_name}
            <br />
            Last Message: {conversation.last_message_body}
          </Typography>
          <Typography
            className={(classes.pos, classes.unread)}
            color="textSecondary"
          >
            Last Message Time: {conversation.last_message_datetime}
            <br />
            Last Message Type: {conversation.last_message_type}
          </Typography>
          <Typography component="p">
            Agent Phone: {conversation.phone}
          </Typography>
        </CardContent>
        <CardActions>
          <Button 
            size="large" 
            variant="contained" 
            onClick={() => selectConversation(conversation)}>
            Continue the Conversation
          </Button>
          {!conversation.read && (
            <Button
              size="large"
              variant="contained"
              onClick={() => markAsRead(conversation.conversation_id, index)}
            >
              Mark as read
            </Button>
          )}
          {conversation.read && (
            <Button
              size="large"
              variant="contained"
              onClick={() => markAsUnread(conversation.conversation_id, index)}
            >
              Mark as unread
            </Button>
          )}
          <Button 
            size="large" 
            variant="contained"
            onClick={() => optoutClick(index)}
          >
            Mark as OptOut
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => markAsArchived(conversation.conversation_id, index)}
          >
            Archive Conversation
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => tagAsWrongNumber(conversation.conversation_id, index)}
          >
            Tag Wrong Number
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ConversationCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConversationCard);
