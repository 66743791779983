import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import CreateClientDialog from "./CreateClientDialog";
import AddOptOutDialog from "./AddOptOutDialog";
import EditClientDialog from "./EditClientDialog";
import MUIDataTable from "mui-datatables";
import Grid from "@material-ui/core/Grid";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import StyledSnackBarContent from "../../Components/StyledSnackBarContent";
import ManagePhoneNumbersDialog from "./ManagePhoneNumbersDialog";
import TextField from '@material-ui/core/TextField';
import OptoutReportDialog from "./OptoutReportDialog";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px 0px 20px 0px",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  reportProgress: {
    color: "white",
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px",
  },
  snackbarClose: {
    padding: theme.spacing.unit / 2,
  },
  optoutButton: {
    backgroundColor: "green",
  },
});

class OrganizationClients extends Component {
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          head: {
            fontWeight: "bold",
            fontSize: "16px",
          },
        },
      },
    });

  state = {
    open: false,
    openOptOut: false,
    optOutClientId: null,
    clients: [],
    isLoading: false,
    editClient: null,
    editOpen: false,
    reportLoading: 0,
    managePhoneNumbersOpen: false,
    managePhoneClient: null,
    clientPhones: [],
    OptoutDial: false
  };

  componentDidMount() {
    this.getOrganizationClients();
  }

  getOrganizationClients = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/get", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            clients: result,
            isLoading: false,
          });
        },
        (error) => {
          console.log("error:" + error);
        }
      );
  };

  createClient = (newClient, _callback) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(newClient),
    })
      .then((response) => {
        this.setState({});
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.getOrganizationClients();
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        this.getOrganizationClients();
      });
  };

  addOptOut = (phone) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/optout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        organization_client_id: this.state.optOutClientId,
        phone: phone,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  deleteClient = (clientId, _callback) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({ client_id: clientId }),
    })
      .then(
        (result) => {
          if (result.ok) _callback();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  updateClient = (clientToUpdate, _callback) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(clientToUpdate),
    })
      .then(
        (result) => {
          if (result.ok) _callback();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  pullOptOutReport = (client_id, start_date, end_date) => {
    this.setState({ reportLoading: client_id, reportSnackBar: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/reports/optout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({ 
        client_id: client_id,
        start_date: start_date,
        end_date: end_date
      }),
    })
      .then(
        (result) => {
          if (!result.ok) {
            throw new Error("Network response was not ok.");
          }
          this.setState({ reportLoading: 0 });
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  getClientPhoneNumbers = (managePhoneClient) => {
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/phonenumbers/get",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
        body: JSON.stringify(managePhoneClient),
        cache: "no-cache",
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            managePhoneNumbersOpen: true,
            managePhoneClient: managePhoneClient,
            clientPhones: result,
          });
        },
        (error) => {
          console.log("error:" + error);
        }
      );
  };

  CreateClientButtonClick = () => {
    this.setState({
      open: true,
    });
  };

  EditClientButtonClick = (client) => {
    this.setState({
      editOpen: true,
      editClient: client,
    });
  };

  addOptOutClicked = (client) => {
    this.setState({
      openOptOut: true,
      optOutClientId: client.id,
    });
  };

  ManagePhoneNumberClientButtonClick = (client) => {
    this.getClientPhoneNumbers(client);
  };

  handlePhoneNumberClose = () => {
    this.setState({ managePhoneNumbersOpen: false, clientPhones: [] });
  };

  handleClose = (clientToAdd) => {
    if (!clientToAdd.target) {
      this.createClient(clientToAdd, () => this.getOrganizationClients);
    }
    this.setState({ open: false });
  };

  handleOptOutClose = (phone) => {
    if (phone) {
      this.addOptOut(phone);
    }
    this.setState({ openOptOut: false });
  };

  handleEditClose = (clientToEdit) => {
    if (clientToEdit && !clientToEdit.target) {
      this.updateClient(clientToEdit, this.getOrganizationClients);
    }
    this.setState({ editOpen: false });
  };

  OpenOptoutReportDialog = (id) => {
    if(id){
      if (Number.isInteger(id)) {
        this.setState({optOutClientId: id})
      }
    }    
    this.setState({OptoutReportDialog: true});
  }

  handleOptoutReportClose = (startDate, endDate) =>{
    this.setState({OptoutReportDialog: false});
    if(startDate && endDate && this.state.optOutClientId){
      this.pullOptOutReport(this.state.optOutClientId, startDate, endDate);
      this.setState({optOutClientId: null})
    }else if(startDate && endDate){
      this.pullOptOutReport(null, startDate, endDate);
    }else{
      //Show error snackbar      
    }
  }

  handleReportSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ reportSnackBar: false });
  };

  render() {
    const { classes } = this.props;
    const columns = [
      {
        name: "name",
        label: "Name",
        options: {
          sort: true,
          sortDirection: "asc",
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          sort: true,
        },
      },
      {
        name: "phone",
        label: "Phone",
        options: {
          sort: true,
        },
      },
      {
        name: "id",
        label: "Phone Numbers",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let row = this.state.clients.find((client) => client.id == value);
            return (
              <Button
                onClick={() => this.ManagePhoneNumberClientButtonClick(row)}
              >
                <EditIcon />
              </Button>
            );
          },
        },
      },
      {
        name: "id",
        label: "Edit",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let row = this.state.clients.find((client) => client.id == value);
            return (
              <Button onClick={() => this.EditClientButtonClick(row)}>
                <EditIcon />
              </Button>
            );
          },
        },
      },
      {
        name: "id",
        label: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                color="error"
                aria-label="Delete"
                onClick={() =>
                  this.deleteClient(value, this.getOrganizationClients)
                }
              >
                <RemoveCircleIcon color="error" />
              </Button>
            );
          },
        },
      },
      {
        name: "id",
        label: "Optout",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let row = this.state.clients.find((client) => client.id == value);
            return (
              <Button
                aria-label="Optout"
                onClick={() => this.addOptOutClicked(row)}
              >
                <AddCircleIcon style={{ color: "green" }} />
              </Button>
            );
          },
        },
      },
      {
        name: "id",
        label: "Optout Report",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (this.state.reportLoading === value) {
              return (
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="Optout Report"
                >
                  <CircularProgress
                    size={20}
                    className={classes.reportProgress}
                  />
                </Button>
              );
            } else {
              return (
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="Optout Report"
                  onClick={() => this.OpenOptoutReportDialog(value)}
                >
                  Run
                </Button>
              );
            }
          },
        },
      },
    ];

    const options = {
      print: false,
      download: false,
      selectableRows: false,
    };

    let agentTable;

    if (this.state.isLoading) {
      agentTable = <CircularProgress className={classes.progress} />;
    } else if (this.state.clients.length > 0) {
      agentTable = (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={"Clients"}
            data={this.state.clients}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      );
    } else {
      agentTable = (
        <Typography varient="body1">
          No clients have been found. Start by adding an client.
        </Typography>
      );
    }
    let editDialog;
    let managePhoneDialog;
    if (this.state.editClient) {
      editDialog = (
        <EditClientDialog
          open={this.state.editOpen}
          onClose={this.handleEditClose}
          editClient={this.state.editClient}
        />
      );
    } else if (this.state.managePhoneClient) {
      managePhoneDialog = (
        <ManagePhoneNumbersDialog
          auth={this.props.auth}
          open={this.state.managePhoneNumbersOpen}
          onClose={this.handlePhoneNumberClose}
          getClientPhoneNumbers={this.getClientPhoneNumbers}
          phoneNumbers={this.state.clientPhones}
          managePhoneClient={this.state.managePhoneClient}
        ></ManagePhoneNumbersDialog>
      );
    } else {
      editDialog = <div></div>;
    }

    return (      
      <div className={classes.root}>
        <OptoutReportDialog
          open={this.state.OptoutReportDialog}
          onClose={this.handleOptoutReportClose}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.reportSnackBar}
          autoHideDuration={3000}
          onClose={this.handleReportSnackBarClose}
        >
          <StyledSnackBarContent
            onClose={this.handleReportSnackBarClose}
            variant="success"
            message="Report queued and will be sent to your email."
          />
        </Snackbar>
        <CreateClientDialog open={this.state.open} onClose={this.handleClose} />
        <AddOptOutDialog
          open={this.state.openOptOut}
          onClose={this.handleOptOutClose}
        />
        {editDialog}
        {managePhoneDialog}
        <Grid container spacing={0} className={classes.rowSpacing}>
          <Grid item xs={12}>
            <Button
              className={classes.buttonRight}
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
              onClick={this.CreateClientButtonClick}
            >
              Add New Client
            </Button>
            <Button
              className={classes.buttonRight}
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
              onClick={this.OpenOptoutReportDialog}
            >
              Pull Full Optout Report
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {agentTable}
          </Grid>
        </Grid>
      </div>
    );
  }
}

OrganizationClients.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrganizationClients);
