import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import AuthContext from "./AuthContext";

//todo - could also validate roles here
function PrivateRoute({ component: Component, roles, redirectUrl, setAgentGoalId, ...rest  }) {
  return (
    <AuthContext.Consumer>
      {auth => (
        <Route
          {...rest}
          render={props => {
            // 1. Redirect to login if not logged in.
            if (!auth.isAuthenticated()) return auth.login();

            //2. Display message if user lacks required scope(s).
            if (roles.length > 0 && !auth.userIsInRoles(roles)) {
              if (redirectUrl) {
                return <Redirect to={redirectUrl} />;
              } else {
                return (
                  <h1>
                    Unauthorized - You need the following role(s) to view this
                    page: {roles.join(",")}.
                  </h1>
                );
              }
            }

            // 3. Render component
            return <Component auth={auth} setAgentGoalId={setAgentGoalId} {...props} />;
          }}
        />
      )}
    </AuthContext.Consumer>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  roles: PropTypes.array,
  redirectUrl: PropTypes.string
};

PrivateRoute.defaultProps = {
  roles: []
};

export default PrivateRoute;
