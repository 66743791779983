import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleDialog from '../../Components/SimpleDialog';
import TextField from '@material-ui/core/TextField';
import { relativeTimeThreshold } from "moment";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    demo: {
        backgroundColor: theme.palette.background.paper
    },
    title: {
        margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`
    },
    groupListItem: {
        cursor: "pointer"
    },
    createGroupButton: {
        marginTop: `${theme.spacing.unit * 2}px`
    },
    column: {
        padding: theme.spacing.unit * 2
    },
    rowSpacing: {
        padding: "20px"
    },
    progress: {
        margin: theme.spacing.unit * 2
    }
});

class OrganizationTagsSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationTags: [],
            isLoading: false,
            deleteDialogOpen: false,
            textFieldTag: '',
            deleteTagId: -1
        }
    }

    componentWillMount = () => {
        this.getOrganizationTags();
    }



    getOrganizationTags = () => {
        this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/tags",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                }
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    this.setState({ organizationTags: response, isLoading: false })
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    };

    createOrganizationTag = (tag) => {
        this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/tags/create",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    tag: tag
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                   this.setState({textFieldTag: ''});
                   this.getOrganizationTags();
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    deleteOrganizationTag = (tagId) => {
        this.setState({ isLoading: true });
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/tags/delete",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    tagId: tagId
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    this.setState({deleteTagId: -1})
                    this.getOrganizationTags();
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    markOrganizationTagActive = (value) => {
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/tags/active",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    tagId: value
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    this.getOrganizationTags();
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    markOrganizationTagInActive = (value) => {
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/tags/inactive",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    tagId: value
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    this.getOrganizationTags();
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

   
    handleDeleteClick = (value) => {
        this.setState({deleteDialogOpen: true, deleteTagId: value});
    }

    handleDeleteClose = (choice) => {
        this.setState({deleteDialogOpen: false});
        if(choice){
            this.deleteOrganizationTag(this.state.deleteTagId);            
        }
    }

    handleMarkActiveClick = (value) => {
        this.markOrganizationTagActive(value);
    }

    handleMarkInActiveClick = (value) => {
        this.markOrganizationTagInActive(value);
    }

    handleTextFieldChange = () => event => {
        this.setState({textFieldTag: event.target.value})
    }

    handleCreateTag = () => {
        this.createOrganizationTag(this.state.textFieldTag);
    }

    render() {
      
        const { classes } = this.props;

        const columns = [
            {
                name: "tag",
                label: "Tag",
                options: {
                    sort: true
                }
            },
            {
                name: "is_active",
                label: "Is Active",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        console.log(tableMeta);
                        let row = this.state.organizationTags[tableMeta.rowIndex];
                        if (value) {                            
                            return (<Button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => this.handleMarkInActiveClick(row.tag_id)}>Active</Button>)
                        } else {
                            return (<Button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => this.handleMarkActiveClick(row.tag_id)}>Inactive</Button>)
                        }
                    }
                }
            },
            {
                name: "tag_id",
                label: "Delete",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Button
                               style={{color: 'red'}}
                                aria-label="Delete"
                                onClick={() =>
                                    this.handleDeleteClick(value)
                                }
                            >
                                <RemoveCircleIcon style={{color: 'red'}} />
                            </Button>
                        );
                    }
                }
            }
        ];

        const options = {
            filterType: "checkbox",
            print: false,
            selectableRows: false
        };
        let tagTableDisplay;

        if (this.state.isLoading) {
            tagTableDisplay = <CircularProgress className={classes.progress} />;
        } else if (this.state.organizationTags.length > 0) {
            tagTableDisplay = (
                <MUIDataTable
                    title={"Manage Tags"}
                    data={this.state.organizationTags}
                    columns={columns}
                    options={options}
                />
            );
        } else {
            tagTableDisplay = (
                <Typography varient="body1">
                    No tags have been found. Start by creating a tag.
                </Typography>
            );
        }

        return (<div>
            <SimpleDialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleDeleteClose}
                    title="Delete Tag"
                    message="Are you sure you wish to delete this tag completly from the system?"
                />
            <Grid container spacing={32} style={{marginBottom: '20px'}}> 
                <Grid item xs={3}>
                    <h1>Tag Management</h1>
                </Grid>
                <Grid item xs={5} style={{paddingTop: "26px"}}>
                    <TextField id="outlined-basic" 
                        value={this.state.textFieldTag} 
                        InputProps={{ style: {height: "39px", padding: "0px 0px 0px 18px"} }}
                        InputLabelProps={{ style: {marginTop: -7}}}
                        fullWidth 
                        label="Tag Value" 
                        variant="outlined"  
                        onChange={this.handleTextFieldChange()} />
                </Grid>
                <Grid item xs={4} style={{paddingTop: "26px"}}>
                    <Button variant="contained" color='primary' onClick={this.handleCreateTag}>Create Tag</Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                   {tagTableDisplay}
                </Grid>
            </Grid>

        </div>);
    }
}

OrganizationTagsSettings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OrganizationTagsSettings);