import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import EditIcon from "@material-ui/icons/Edit";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import StyledSnackBarContent from "../../Components/StyledSnackBarContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ManagePhoneNumbersAddDialog from "./ManagePhoneNumbersAddDialog";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  appBar: {
    alignItems: "end",
  },
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px 0px 20px 0px",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  reportProgress: {
    color: "white",
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px",
  },
  snackbarClose: {
    padding: theme.spacing.unit / 2,
  },
});

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class ManagePhoneNumbersDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumbers: [],
      addDialogOpen: false,
      isLoading: false,
      showSnackBarSuccess: false,
      showSnackBarError: false,
    };
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          head: {
            fontWeight: "bold",
            fontSize: "16px",
          },
        },
      },
    });

  addClientPhoneNumber = (phoneNumber, clientId) => {
    this.setState({ isLoading: true });
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/phonenumbers/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
        body: JSON.stringify({ phone: phoneNumber.phone, client_id: clientId }),
        cache: "no-cache",
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.result) {
            this.setState({
              clients: result,
              isLoading: false,
              showSnackBarSuccess: true,
            });
            this.props.getClientPhoneNumbers(this.props.managePhoneClient);
          } else {
            this.setState({
              isLoading: false,
              showSnackBarError: true,
            });
            this.props.getClientPhoneNumbers(this.props.managePhoneClient);
          }
        },
        (error) => {
          console.log("error:" + error);
        }
      );
  };

  deleteClientPhoneNumber = (phoneNumber, _callback) => {
    this.setState({ isLoading: true });
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/phonenumbers/delete",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
        body: JSON.stringify({
          phone_number_id: phoneNumber,
          client_id: this.props.managePhoneClient.id,
        }),
        cache: "no-cache",
      }
    )
      .then(
        (result) => {
          if (result.ok) {
            this.props.getClientPhoneNumbers(this.props.managePhoneClient);
            this.setState({ isLoading: false });
          }
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  handlePhoneNumberClose = () => {
    this.props.onClose();
  };

  handleCreatePhoneNumber = () => {
    this.setState({ addDialogOpen: true });
  };

  handleCreatePhoneNumberClose = (clientPhoneNumber) => {
    this.setState({ addDialogOpen: false });
    if (!clientPhoneNumber.target) {
      this.addClientPhoneNumber(
        clientPhoneNumber,
        this.props.managePhoneClient.id
      );
    }
  };

  handleDeletePhoneNumber = (value) => {
    this.deleteClientPhoneNumber(value);
  };

  handleClose = () => {
    this.props.onClose();
  };

  handlePhoneSuccessSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showSnackBarSuccess: false });
  };

  handlePhoneErrorSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showSnackBarError: false });
  };

  render() {
    const { classes, open, onClose } = this.props;
    const columns = [
      {
        name: "phone",
        label: "Phone Number",
        options: {
          sort: true,
          sortDirection: "asc",
        },
      },
      {
        name: "create_date",
        label: "Date Created",
        options: {
          sort: true,
        },
      },
      {
        name: "phone_number_id",
        label: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                color="error"
                aria-label="Delete"
                onClick={() => this.handleDeletePhoneNumber(value)}
              >
                <RemoveCircleIcon color="error" />
              </Button>
            );
          },
        },
      },
    ];

    const options = {
      print: false,
      download: false,
      selectableRows: false,
    };

    let phoneTable;

    if (this.state.isLoading) {
      phoneTable = <CircularProgress className={classes.progress} />;
    } else if (this.props.phoneNumbers.length > 0) {
      phoneTable = (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={this.props.phoneNumbers}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      );
    } else {
      phoneTable = (
        <Typography varient="body1">
          No client phone numbers have been found. Start by adding a phone
          number.
        </Typography>
      );
    }

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.handleClose}
        aria-labelledby="edit-group-dialog"
        TransitionComponent={Transition}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.showSnackBarSuccess}
          autoHideDuration={3000}
          onClose={this.handlePhoneSuccessSnackBarClose}
        >
          <StyledSnackBarContent
            onClose={this.handlePhoneSuccessSnackBarClose}
            variant="success"
            message="Success your phone has been verified and added."
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.showSnackBarError}
          autoHideDuration={3000}
          onClose={this.handlePhoneErrorSnackBarClose}
        >
          <StyledSnackBarContent
            onClose={this.handlePhoneErrorSnackBarClose}
            variant="error"
            message="Error your the phone number is not registered in plivo."
          />
        </Snackbar>
        <ManagePhoneNumbersAddDialog
          open={this.state.addDialogOpen}
          onClose={this.handleCreatePhoneNumberClose}
        ></ManagePhoneNumbersAddDialog>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              className={classes.buttonRight}
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle id="edit-group-dialog"></DialogTitle>
        <DialogContent>
          <h1 className={classes.title}>
            {this.props.managePhoneClient.name
              ? this.props.managePhoneClient.name
              : "Client"}{" "}
            Phone Numbers
          </h1>
          <Typography>
            Client Id:{" "}
            {this.props.managePhoneClient
              ? this.props.managePhoneClient.id
              : ""}
          </Typography>
          <Grid container spacing={0} className={classes.rowSpacing}>
            <Grid item xs={12}>
              <Button
                className={classes.buttonRight}
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
                onClick={this.handleCreatePhoneNumber}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {phoneTable}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handlePhoneNumberClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ManagePhoneNumbersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(ManagePhoneNumbersDialog);
