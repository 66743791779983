import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import CreateAgentDialog from "./CreateAgentDialog";
import AddExistingAgentsDialog from "./AddExistingAgentsDialog";
import MUIDataTable from "mui-datatables";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px 0px 20px 0px",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px"
  }
});

class Agents extends Component {
  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
            fontWeight: "bold",
            fontSize: "16px" 
        }
      }
    }
  })

  state = {
    open: false,
    existingagentsopen: false,
    agents: [],
    existingAgents: [],
    organizationAgents: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getAgents();
    this.getExistingAgents();
  }

  getAgents = () => {
    let { id } = this.props.group_id;
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/get/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            agents: result,
            isLoading: false,
          });
          this.props.set_tab_count(result.length)
        },
        (error) => {
          console.log("error:" + error);
        }
      );
  };

  createAgent = (newAgent, _callback) => {
    let { id } = this.props.group_id;
    newAgent.group_id = id;
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(newAgent),
    })
      .then((response) => {
        this.setState({});
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.getAgents();
          this.getExistingAgents();
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        this.getAgents();
        this.getExistingAgents();
      });
  };

  createAgentGroupLinks = (linksToAdd, _callback) => {
    let body = {
      agentGroupLinks: linksToAdd,
      group_id: this.props.group_id.id
    }
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/create-agent-group-links", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.getAgents();
          this.getExistingAgents();
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        this.getAgents();
        this.getExistingAgents();
      });
  };

  removeAgents = (agentsToRemove, _callback) => {
    let body = {
      agent_ids: agentsToRemove,
    }
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/hide-agents", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.getAgents();
          this.getExistingAgents();
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        this.getAgents();
        this.getExistingAgents();
      });
  };

  markAgentInactive = (agentId, _callback) => {
    let { id } = this.props.group_id;
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/deactivate/" + agentId + "/" + id,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then(
        (result) => {
          if (result.ok) _callback();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  CreateAgentButtonClick = () => {
    this.setState({
      open: true,
    });
  };

  AddExistingAgentsButtonClick = () => {
    this.setState({
      existingagentsopen: true,
    });
  };

  handleClose = (agentToAdd) => {
    if (!agentToAdd.target) {
      this.createAgent(agentToAdd, () => this.getAgents);
    }
    this.setState({ open: false });
  };

  handleAddExistingAgentsClose = (selectedAgents, action) => {
    if (!selectedAgents.target && action === "add") {
      this.createAgentGroupLinks(selectedAgents, () => this.getAgents);
    }
    if (!selectedAgents.target && action === "remove") {
      this.removeAgents(selectedAgents, () => this.getAgents);
    }
    this.setState({ existingagentsopen: false });
  };

  setAgentSelections = (orgAgents) => {
    let agents_ids_in_group = this.state.agents.map(function(x) { return x.id; });
    let agents_not_in_group = orgAgents.filter(function(x) { return !agents_ids_in_group.includes(x.id); });
    this.setState({
      existingAgents: agents_not_in_group,
      isLoading: false,
    });
  };

  getExistingAgents = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/get", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            organizationAgents: result
          });
          this.setAgentSelections(result);
        },
        (error) => {
          console.log("error:" + error);
          this.setState({
            existingAgents: [],
            isLoading: false,
          });
        }
      );
  };

  render() {
    const { classes } = this.props;
    const columns = [
      {
        name: "name",
        label: "Name",
        options: {
          sort: true,
          sortDirection: "asc",
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          sort: true,
        },
      },
      {
        name: "id",
        label: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                color="error"
                aria-label="Delete"
                onClick={() => this.markAgentInactive(value, this.getAgents)}
              >
                <RemoveCircleIcon color="error" />
              </Button>
            );
          },
        },
      },
    ];

    const options = {
      print: false,
      download: false,
      selectableRows: false,
    };

    let agentTable;

    if (this.state.isLoading) {
      agentTable = <CircularProgress className={classes.progress} />;
    } else if (this.state.agents.length > 0) {
      agentTable = (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={"Agents"}
            data={this.state.agents}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      );
    } else {
      agentTable = (
        <Typography varient="body1">
          No agents have been found. Start by adding an agent.
        </Typography>
      );
    }

    return (
      <div className={classes.root}>
        <CreateAgentDialog open={this.state.open} onClose={this.handleClose} />
        <AddExistingAgentsDialog existingAgents={this.state.existingAgents} open={this.state.existingagentsopen} group_id={this.props.group_id} auth={this.props.auth} onClose={this.handleAddExistingAgentsClose} />
        <Grid container spacing={0} className={classes.rowSpacing}>
          <Grid item xs={12}>
            <Button
              className={classes.buttonRight}
              variant="contained"
              color="primary"
              onClick={this.AddExistingAgentsButtonClick}
            >
              Add/Remove Existing Agents
            </Button>
            <Button
              className={classes.buttonRight}
              variant="contained"
              color="primary"
              onClick={this.CreateAgentButtonClick}
            >
              Add New Agent
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {agentTable}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Agents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Agents);
