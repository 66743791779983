import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import StyledSnackBarContent from "../../Components/StyledSnackBarContent";
import Snackbar from "@material-ui/core/Snackbar";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedContacts: [],
      isLoading: true,
      messageToSend: "",
      getNextConversation: false,
      contact: {},
      showSnackBar10dlcError: false
    };
  }

  componentWillMount() {
    this.setState({ isLoading: false });
    this.getContactForConversation();
  }

  getContactForConversation = () => {
    if (this.state.cachedContacts.length > 0) {
      var nextContact = this.state.cachedContacts.pop();
      this.setState({
        cachedContacts: this.state.cachedContacts,
        contact: nextContact,
        isLoading: false,
        getNextConversation: true,
        messageToSend: nextContact.goal_script,
      });
    } else {
      var goal_id = this.props.goal.goal_id;
      this.setState({ isLoading: true, messageToSend: "" });
      fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/conversation/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
        body: JSON.stringify({ goal_id: goal_id }),
      })
        .then((response) => {
          if (response.ok) return response.json();
          throw new Error("Network response was not ok.");
        })
        .then((response) => {
          if (response && response != "No more contacts") {
            var firstContact = response.pop();
            this.setState({
              cachedContacts: response,
              isLoading: false,
              getNextConversation: true,
              messageToSend: firstContact.goal_script,
              contact: firstContact,
            });
            this.refreshMessages();
          } else {
            this.setState({
              isLoading: false,
              getNextConversation: false,
            });
          }
        })
        .catch((error) => this.setState({ errorMessage: error.message }));
    }
  };

  sendMessage = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/message/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        contact_id: this.state.contact.contact_id,
        goal_id: this.props.goal.goal_id,
        message: this.state.messageToSend,
      }),
    })
    .then((res) => res.json())      
      .then((response) => {
        if (response == "error") {
          //this is where we could do a popup alert or something.
          this.setState({showSnackBar10dlcError: true});
        }        
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
    if (this.state.getNextConversation) {
      this.getContactForConversation();
    } else {
      this.refreshMessages();
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleSend = () => {
    this.sendMessage();
  };

  handle10dlcPhoneErrorSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showSnackBar10dlcError: false });
  };

  calcSms = (count) => {
    if (count === 0) {
      return "0/0";
    }
    let hold =
      count +
      "/" +
      (Math.floor(count / 160) === 0 ? 1 : Math.ceil(count / 160));
    return hold;
  };

  render() {
    const { classes, history, goal } = this.props;
    let { isLoading, messageToSend } = this.state;
    let chatButtons = (
      <Grid container>
        <Grid item xs={9}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading || (!goal.mms && this.state.messageToSend === "")}
            className={classes.button}
            onClick={this.sendMessage}
          >
            Send
          </Button>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    );

    let chat = (
      <>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            {goal.mms && <Typography variant="h5">MMS</Typography>}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={7}>
            <TextField
              id="outlined-multiline-flexible"
              label="Send Message"
              multiline={true}
              fullWidth={true}
              value={messageToSend}
              onChange={this.handleChange("messageToSend")}
              margin="normal"
              variant="outlined"
              autoFocus={true}
              helperText={this.calcSms(messageToSend.length)}
              error={!goal.mms && this.state.messageToSend === ""}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Typography variant="h5">
              Name: {this.state.contact.first_name}
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Typography variant="h5">
              Phone: {this.state.contact.to_phone}
            </Typography>
          </Grid>
        </Grid>
      </>
    );

    if (isLoading) {
      return <h1>Loading Conversations...</h1>;
    } else if (this.state.getNextConversation) {
      return (
        <div>
          <Grid container>
            {chatButtons}
            {chat}
          </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.showSnackBar10dlcError}
          autoHideDuration={3000}
          onClose={this.handle10dlcPhoneErrorSnackBarClose}
        >
          <StyledSnackBarContent
            onClose={this.handle10dlcPhoneErrorSnackBarClose}
            variant="error"
            message="Error in sending, contact your administrator"
          />
        </Snackbar>
        </div>
      );
    } else {
      return <h1>No More Conversations</h1>;
    }
  }
}

export default withStyles(styles)(Conversation);
