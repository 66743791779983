import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    emailError: false,
  },
});

class CreateClientDialog extends Component {
  state = {
    name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
  };

  handleCreate = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email === "" || this.state.email.match(emailRegex)) {
      let client = {
        name: this.state.name,
        email: this.state.email,
        address_1: this.state.address_1,
        address_2: this.state.address_2,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        phone: this.state.phone,
      };
      this.props.onClose(client);
      this.setState({
        name: "",
        phone: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        email: "",
      });
    } else {
      this.setState({ emailError: true });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog"
      >
        <DialogTitle id="create-group-dialog">Add a Client</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="create-client-name"
            label="Name"
            className={classes.textField}
            onChange={this.handleChange("name")}
            margin="normal"
            name="client.name"
          />
          <TextField
            error={this.state.emailError}
            id="create-client-email"
            label="Email"
            className={classes.textField}
            onChange={this.handleChange("email")}
            margin="normal"
            name="client.email"
          />
          <TextField
            autoFocus
            id="create-client-address_1"
            label="Address"
            className={classes.textField}
            onChange={this.handleChange("address_1")}
            margin="normal"
            name="client.address_1"
          />
          <TextField
            id="create-client-address_2"
            label="Address"
            className={classes.textField}
            onChange={this.handleChange("address_2")}
            margin="normal"
            name="client.address_2"
          />
          <TextField
            id="create-client-city"
            label="City"
            className={classes.textField}
            onChange={this.handleChange("city")}
            margin="normal"
            name="client.city"
          />
          <TextField
            id="create-client-state"
            label="State"
            className={classes.textField}
            onChange={this.handleChange("state")}
            margin="normal"
            name="client.state"
          />
          <TextField
            id="create-client-zip"
            label="Zip"
            className={classes.textField}
            onChange={this.handleChange("zip")}
            margin="normal"
            name="client.zip"
          />
          <TextField
            id="create-client-phone"
            label="Phone"
            className={classes.textField}
            onChange={this.handleChange("phone")}
            margin="normal"
            name="client.phone"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCreate} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateClientDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(CreateClientDialog);
