import React from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import Table from "@material-ui/core/Table";

export default function MessageChat(props) {
  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  }

  return (
    <Table>
      <TableBody>
        {props.messages.map((message, index) => (
          <TableRow key={message.message_type + index}>
            {message.message_type === "sent" && (
              <TableCell align="left">
                {formatDate(message.create_date)}
              </TableCell>
            )}
            <TableCell
              align={message.message_type === "sent" ? "right" : "left"}
            >
              {message.message}
            </TableCell>
            {message.message_type !== "sent" && (
              <TableCell align="right">
                {formatDate(message.create_date)}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
