import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import { Button } from "@material-ui/core";
import SimpleDialog from "../../Components/SimpleDialog";
import Grid from "@material-ui/core/Grid";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`
  },
  groupListItem: {
    cursor: "pointer"
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`
  },
  column: {
    padding: theme.spacing.unit * 2
  },
  rowSpacing: {
    padding: "20px"
  },
  textPadding: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  removeButtonMargin: {
    marginBottom: "20px"
  },
  settingTitle: {
    marginTop: "20px"
  }
});

class GroupSettings extends Component {
  state = {
    open: false
  };

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  openRemovePrompt = () => {
    this.setState({
      open: true
    });
  };

  handleClose = choice => {
    const { id } = this.props.match.params;
    if (choice) {
      fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/deactivate/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`
        }
      })
        .then(response => {
          if (response.ok) return response.json();
          throw new Error("Network response was not ok.");
        })
        .then(() => {
          window.location.href = "/";
        })
        .catch(error => this.setState({ message: error.message }));

      //need to show confirmation of group removed
      //navigate to group page
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <SimpleDialog
          open={this.state.open}
          onClose={this.handleClose}
          title="Remove Group"
          message="Are you sure you want to remove this group?"
        />
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.title}>
              Group Settings
            </Typography>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={1}>
            <Button variant="outlined" onClick={this.goBack}>
              Back
            </Button>
          </Grid>
        </Grid>

        <Divider />
        <Typography variant="h5" className={classes.settingTitle}>
          Remove Group
        </Typography>
        <Typography component="p" className={classes.textPadding}>
          Removing the group will delete the contacts, agents and goals of the
          group.
        </Typography>
        <Button
          className={classes.removeButtonMargin}
          variant="contained"
          color="secondary"
          onClick={this.openRemovePrompt}
        >
          Remove Group
        </Button>
        <Divider />
      </div>
    );
  }
}

GroupSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupSettings);
