import React, { Component } from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ManageGoal from "./ManageGoal";
import DeactivateGoalDialog from "./DeactivateGoalDialog";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { DateTime } from "luxon";
import StyledSnackBarContent from "../../Components/StyledSnackBarContent";
import Snackbar from "@material-ui/core/Snackbar";
import Check from "@material-ui/icons/Check";
import { TramOutlined } from "@material-ui/icons";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px 0px 20px 0px",
  },
  textPadding: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  removeButtonMargin: {
    marginBottom: "20px",
  },
  settingTitle: {
    marginTop: "20px",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px",
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

class Goals extends Component {
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          head: {
            fontWeight: "bold",
            fontSize: "16px",
          },
        },
      },
    });

  state = {
    open: false,
    goals: [],
    groupid: -1,
    manageGoal: false,
    removeGoalDialogOpen: false,
    selectedGoal: -1,
    isLoading: false,
    disable10dlc: false,
    snackbarOpen: false,
  };

  componentDidMount() {
    this.getGoals();
    this.getClientGoalPhones();
  }

  getGoals = () => {
    let { id } = this.props.group_id;
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/get/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({ goals: response, isLoading: false });
        this.props.set_tab_count(response.length);
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  getClientGoalPhones = () => {
    const groupId = this.props.group_id.id;
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + `/goals/client/phone/${groupId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          if (response.length > 0) {
            this.setState({ disable10dlc: false, snackbarOpen: false });
          } else {
            if (this.state.goals.some((goal) => goal.is_10dlc === true)) {
              this.setState({ disable10dlc: true, snackbarOpen: true });
            } else {
              this.setState({ disable10dlc: true, snackbarOpen: false });
            }
          }
        }
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  markGoalInactive = (goalId, _callback) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/deactivate/" + goalId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) _callback();
        throw new Error("Network response was not ok.");
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  markGoalLocked = (goal_id) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/lock", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      }, 
      body: JSON.stringify({
        goal_id: goal_id,
      }),
    }) 
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.getGoals();
        }
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  markGoalUnlocked = (goal_id) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/unlock", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        goal_id: goal_id,
      }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.getGoals();
        }
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  onCreateGoalClick = () => {
    this.setState({ manageGoal: true });
  };

  cancelManage = () => {
    this.setState({ manageGoal: false });
    this.getGoals();
  };

  deactivateAction = (choice) => {
    this.setState({ removeGoalDialogOpen: false, selectedGoal: -1 });
    if (choice) {
      this.markGoalInactive(this.state.selectedGoal, this.getGoals);
    }
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { disable10dlc, snackbarOpen } = this.state;
    const columns = [
      {
        name: "goal_name",
        label: "Name",
        options: {
          sort: true,
        },
      },
      {
        name: "goal_description",
        label: "Description",
        options: {
          sort: true,
        },
      },
      {
        name: "goal_start_time",
        label: "Goal Start Time",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let row = this.state.goals[tableMeta.rowIndex];
            var date = DateTime.fromISO(row.goal_start_time);
            return <span>{date.toLocaleString(DateTime.DATETIME_SHORT)}</span>;
          },
        },
      },
      {
        name: "goal_end_time",
        label: "Goal end time",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let row = this.state.goals[tableMeta.rowIndex];
            var date = DateTime.fromISO(row.goal_end_time);
            return <span>{date.toLocaleString(DateTime.DATETIME_SHORT)}</span>;
          },
        },
      },
      {
        name: "goal_create_date",
        label: "Goal Create Date",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let row = this.state.goals[tableMeta.rowIndex];
            var date = DateTime.fromISO(row.goal_create_date);
            return <span>{date.toLocaleString(DateTime.DATETIME_SHORT)}</span>;
          },
        },
      },
      {
        name: "is_10dlc",
        label: "10 DLC",
        options: {
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return (
                <span>
                  <Check />
                </span>
              );
            }
          },
        },
      },
      {
        name: "is_mms",
        label: "MMS",
        options: {
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return (
                <span>
                  <Check />
                </span>
              );
            }
          },
        },
      },
      {
        name: "is_locked",
        label: "Lock",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let row = this.state.goals[tableMeta.rowIndex];
            if (!value) {
              return (
                <Button
                  style={{ backgroundColor: "green", color: "white" }}
                  onClick={() => this.markGoalLocked(row.goal_id)}
                >
                  Active
                </Button>
              );
            } else {
              return (
                <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => this.markGoalUnlocked(row.goal_id)}
                >
                  Inactive
                </Button>
              );
            }
          },
        },
      },
      {
        name: "goal_id",
        label: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                aria-label="Delete"
                onClick={() =>
                  this.setState({
                    removeGoalDialogOpen: true,
                    selectedGoal: value,
                  })
                }
              >
                <RemoveCircleIcon color="error" />
              </Button>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      print: false,
      download: false,
      selectableRows: false,
      expandableRows: true,
      renderExpandableRow: (rowData, rowMeta) => {
        const colSpan = rowData.length + 1;
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10}>
                  <Typography>Initial Script</Typography>
                  <Paper>
                    <Typography>
                      {this.state.goals[rowMeta.rowIndex].script}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </TableCell>
          </TableRow>
        );
      },
    };

    let goalsTable;

    if (this.state.isLoading) {
      goalsTable = <CircularProgress className={classes.progress} />;
    } else if (this.state.goals.length > 0) {
      goalsTable = (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={"Goals"}
            data={this.state.goals}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      );
    } else {
      goalsTable = (
        <Typography varient="body1">
          No goals have been found. Start by creating a goal.
        </Typography>
      );
    }

    let goals;

    if (this.state.manageGoal === true) {
      goals = (
        <ManageGoal
          disable10dlc={disable10dlc}
          groupId={this.props.group_id.id}
          cancelManage={this.cancelManage}
          auth={this.props.auth}
        />
      );
    } else {
      goals = (
        <div>
          <Grid container className={classes.rowSpacing}>
            <Grid item xs={12}>
              <Button
                className={classes.buttonRight}
                variant="contained"
                color="primary"
                onClick={this.onCreateGoalClick}
              >
                Create Goal
              </Button>
            </Grid>
          </Grid>

          {goalsTable}

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={snackbarOpen}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
          >
            <StyledSnackBarContent
              onClose={this.handleSnackbarClose}
              variant="error"
              className={classes.margin}
              message="No registered 10 DLC numbers. Agents sending messages on goals
          set to 10 DLC will fail. Add a registered phone number to the client
          in order to continue sending goals set to 10 DLC."
            />
          </Snackbar>
        </div>
      );
    }

    return (
      <div>
        <DeactivateGoalDialog
          open={this.state.removeGoalDialogOpen}
          onClose={this.deactivateAction}
          title="Remove Goal"
          message="Are you sure you want to remove this goal?"
        />
        {goals}
      </div>
    );
  }
}

Goals.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Goals);
