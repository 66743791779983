import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function KeywordAutoResponses(props) {
  const calcSms = (count) => {
    if (count === 0) {
      return "0/0";
    }
    let hold =
      count +
      "/" +
      (Math.floor(count / 160) === 0 ? 1 : Math.ceil(count / 160));
    return hold;
  };

  const ListScripts = props.scripts.map((script, index) => {
    return (
      <Grid item xs={4} key={script.id}>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          fullWidth={true}
          onChange={props.handleKeywordTitleChange(script)}
        />
        <TextField
          id="outlined-basic"
          label="Keyword"
          variant="outlined"
          fullWidth={true}
          onChange={props.handleKeywordChange(script)}
          margin="normal"
        />
        <FormControl variant="outlined" >
        <InputLabel id="demo-simple-select-outlined-label">Match Rule</InputLabel>
        <Select
          id="demo-simple-select-outlined"
          value={script.matchRule}
          onChange={props.handleKeywordMatchRuleChange(script)}
          label="Match Rule"
        >
          <MenuItem value={'contains'}>Contains</MenuItem>
          <MenuItem value={'starts'}>Starts</MenuItem>
          <MenuItem value={'ends'}>Ends</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{paddingLeft: 10}}>
        <InputLabel id="demo-simple-select-outlined-label">Message Tier</InputLabel>
        <Select
          id="demo-simple-select-outlined"
          value={script.tier}
          onChange={props.handleKeywordtierChange(script)}
          label="Message Tier"
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>          
        </Select>
      </FormControl>
        <TextField
          id="filled-multiline-flexible-new-contacts"
          label="Script Body"
          multiline
          rowsMax="6"
          onChange={props.handleKeywordBodyChange(script)}
          margin="normal"
          variant="outlined"
          helperText={calcSms(script.body.length)}
          fullWidth={true}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.removeKeyword(index);
          }}
        >
          Remove
        </Button>
      </Grid>
    );
  });

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <h1>Keyword AutoResponses</h1>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.createKeywordAutoResponse}
            disabled={props.isLoading}      
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={8}></Grid>
      </Grid>
      {ListScripts.length > 0 && (
        <Grid container spacing={32}>
          {ListScripts}
        </Grid>
      )}
    </div>
  );
}
