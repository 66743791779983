import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CreateGroupDialog from "./CreateGroupDialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Group from "@material-ui/icons/Group";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import {DateTime} from "luxon";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px"
  },
  rowSpacing: {
    padding: "20px 0px 20px 0px",
  },
});

class Groups extends Component {
  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          fontWeight: "bold",
          fontSize: "16px"
        }
      }
    }
  })

  state = {
    open: false,
    groups: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getGroups();
  }

  getGroups = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/get", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            groups: result,
            isLoading: false,
          });
        },
        (error) => {
          console.log("error:" + error);
        }
      );
  };

  createGroup = (newGroup, _callback) => {
    newGroup.organization_id = 1;
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(newGroup),
    })
      .then(
        (result) => {
          if (result.ok) _callback();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = (groupToAdd) => {
    if (groupToAdd != null) {
      this.createGroup(groupToAdd, this.getGroups);
    }
    this.setState({ open: false });
  };


  render() {
    const { classes } = this.props;
    let columns
    const clientCheck = (element) => {
      if (element.client_id) {
        return true;
      } else {
        return false;
      }
    }
    
      columns = [
        {
          name: "client_name",
          label: "Client",
          options: {
            sort: true,
          }
        },
        {
          name: "name",
          label: "Name",
          options: {
            sort: true,
          },
        },
        {
          name: "create_date",
          label: "Create Date",
          options: {
            filter: false,
            sort: true,
            empty: true,
            sortDirection: "desc",
            customBodyRender: (value, tableMeta, updateValue) => {
              var date = DateTime.fromISO(value);
            return (<span>{date.toLocaleString(DateTime.DATETIME_SHORT)}</span>)
            }
          }
        },  
        {
          name: "group_id",
          label: "Action",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <a href={"/grouplanding/" + value}>
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    Open
                  </Button>
                </a>
              );
            },
          },
        },
      ];
    


    const options = {
      print: false,
      download: false,
      selectableRows: false,
      viewColumns: false
    };



    let groupList;

    if (this.state.isLoading) {
      groupList = <CircularProgress className={classes.progress} />;
    } else {
      groupList = (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={this.state.groups}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      );
    }

    return (
      <div className={classes.root}>
        
        <Typography variant="h3" className={classes.title}>
          Groups
        </Typography>
        <Grid container spacing={0} className={classes.rowSpacing}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleClickOpen}
              className={classes.buttonRight}
            >
              Create Group
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {groupList}
          </Grid>
        </Grid>
        <CreateGroupDialog
              open={this.state.open}
              onClose={this.handleClose}
              auth = {this.props.auth}
            />
      </div>
    );
  }
}

Groups.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Groups);
