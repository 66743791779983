import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    emailError: false,
  },
});

class OptoutReportDialog extends Component {
  state = {
    startDate: "2023-01-01",
    endDate: "2023-12-31"
  };

  componentDidMount = () => {
    this.setState({startDate: "2023-01-01", endDate: "2023-12-31"})
  }

  handleRun = () => {
    this.props.onClose(this.state.startDate, this.state.endDate);
    this.setState({startDate: "2023-01-01", endDate: "2023-12-31"})
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({startDate: "2023-01-01", endDate: "2023-12-31"})
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleStartDateChange = (date) => {
    if(date.target.value){
      this.setState({startDate: date.target.value})
    }  
  }

  handleEndDateChange = (date) => {
    if(date.target.value){
      this.setState({endDate: date.target.value})
    }  
  }

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog"
      >
        <DialogTitle id="create-group-dialog">Optout Report</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
            <form className={classes.container} noValidate>
              <TextField
                id="date"
                label="Start Date"
                type="date"                
                value={this.state.startDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleStartDateChange}
              />
            </form>            
            </Grid>
            <Grid item xs={6}>
            <form className={classes.container} noValidate>
              <TextField
                id="date"
                label="End Date"
                type="date"                
                value={this.state.endDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleEndDateChange}
              />
            </form>
            </Grid>
          </Grid>          
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleRun} color="primary" autoFocus>
            Run
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

OptoutReportDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(OptoutReportDialog);
