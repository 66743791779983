import React from 'react';
import { red } from '@material-ui/core/colors';

export default function OptOutContact(props){

    return (
        <div hidden={props.hidden}>
            <span style={{color: 'red'}}>The contact you tried to create has opted out of getting text messages.</span>
        </div>
    );
}