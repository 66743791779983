import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Callback from "./Callback";
import Nav from "./Nav";
import Auth from "./Auth/Auth";
import PrivateRoute from "./PrivateRoute";
import AuthContext from "./AuthContext";
import Groups from "./Pages/AdminGroups/Groups";
import GroupLanding from "./Pages/AdminGroupLanding/GroupLanding";
import GroupSettings from "./Pages/AdminGroupSettings/GroupSettings";
import AgentGoalSelect from "./Pages/AgentGoalSelection/AgentGoalSelect";
import ConversationHistory from "./Pages/AgentPastConversations/ConversationSelect";
import MassImport from "./Pages/AdminMassImport/MassImport";
import NgpVanContactImport from "./Pages/AdminNgpVanImport/NgpVanContactImport";
import OrganizationSettings from "./Pages/AdminOrganizationSettings/OrganizationSettings";
import TagVanMapping from "./Pages/AdminOrganizationIntegrations/TagVanMapping";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: new Auth(this.props.history),
      tokenRenewalComplete: false,
    };
  }

  componentDidMount() {
    this.state.auth.renewToken(() =>
      this.setState({ tokenRenewalComplete: true })
    );
  }

  render() {
    const { auth } = this.state;

    let navbarDisplay = "";
    navbarDisplay = <Nav auth={auth} />;

    // Show loading message until the token renewal check is completed.
    if (!this.state.tokenRenewalComplete) return "Loading...";
    return (
      <AuthContext.Provider value={auth}>
        {navbarDisplay}
        <div className="body">
          <Switch>
            <Route
              path="/callback"
              exact
              render={(props) => <Callback auth={auth} {...props} />}
            />
            <PrivateRoute
              path="/"
              exact
              component={Groups}
              auth={this.state.auth}
              roles={["orgAdmin"]}
              redirectUrl="/goal/select"
            />
            <PrivateRoute
              path="/settings"
              exact
              component={OrganizationSettings}
              auth={this.state.auth}
              roles={["orgAdmin"]}
            />
            <PrivateRoute
              path="/grouplanding/:id"
              component={GroupLanding}
              auth={auth}
              roles={["orgAdmin"]}
            />
            <PrivateRoute
              path="/groupsettings/:id"
              component={GroupSettings}
              auth={auth}
              roles={["orgAdmin"]}
            />
            <PrivateRoute
              path="/goal/select"
              component={AgentGoalSelect}
              roles={["orgAgent"]}
              auth={auth}
            />
            <PrivateRoute
              path="/conversationlist"
              component={ConversationHistory}
              roles={["orgAgent"]}
              auth={auth}
            />
            <PrivateRoute
              path="/massimport/:id"
              component={MassImport}
              roles={["orgAdmin"]}
              auth={auth}
            />
            <PrivateRoute
              path="/ngpvancontactimport/:id"
              component={NgpVanContactImport}
              roles={["orgAdmin"]}
              auth={auth}
            />
            <PrivateRoute
              path="/integrations/npgvan/mapping"
              component={TagVanMapping}
              roles={["orgAdmin"]}
              auth={auth}
            />
          </Switch>
        </div>
      </AuthContext.Provider>
    );
  }
}

export default App;
