import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import PrivateTab from "../../PrivateTab";
import Typography from "@material-ui/core/Typography";
import OrganizationTagsSettings from "../AdminOrganizationTagsSettings/OrganizationTagsSettings";
import OrganizationIntegrations from "../AdminOrganizationIntegrations/OrganizationIntegrations";
import OrganizationClients from "../AdminOrganizationClients/OrganizationClients";


const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    margin: 24
  }
});

class OrganizationSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         value: 0
        };
      }
  

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>
        <Paper className={classes.root}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={"Tags"} />
            <Tab label={"Integrations"} />
            <Tab label={"Clients"} />
          </Tabs>
        </Paper>
        {value === 0 && (
          <PrivateTab
            component={OrganizationTagsSettings}
          ></PrivateTab>
        )}
        {value === 1 && (
          <PrivateTab component={OrganizationIntegrations} />
        )}
        {value === 2 && (
          <PrivateTab component={OrganizationClients} />
        )}     
      </div>
    );
  }
}

OrganizationSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OrganizationSettings);
