import React from "react";
import PropTypes from "prop-types";
import AuthContext from "./AuthContext";
import Typography from "@material-ui/core/Typography";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

//todo - could also validate roles here

function PrivateTab({ component: Component, scopes, ...rest }) {
  return (
    <AuthContext.Consumer>
      {auth => (
        <TabContainer>
          <Component auth={auth} {...rest} />
        </TabContainer>
      )}
    </AuthContext.Consumer>
  );
}

PrivateTab.propTypes = {
  component: PropTypes.func.isRequired,
  scopes: PropTypes.array
};

PrivateTab.defaultProps = {
  scopes: []
};

export default PrivateTab;
