import React, { Component } from "react";
import ConversationCard from "./ConversationCard";
import PastConversation from "../AgentPastConversations/PastConversationChat";
import Button from "@material-ui/core/Button";
import update from "immutability-helper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const LIMIT = 20;

const styles = {
  formControl: {
    margin: 4,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 8,
  },
};

class ConversationSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: [],
      agent_id: 1,
      loading: true,
      noMoreResults: false,
      lastArchived: null,
      read: "",
      sortOrder: "ASC",
      conversationIdLastRowValue: "",
      undoMessageOpen: false,
      optOutUndoMessageOpen: false,
      lastOptOut: null,
      lastOptOutIndex: null,
    };
  }

  componentWillMount() {
    this.getConversations();
  }

  resetState = () => {
    this.setState({
      conversations: [],
      agent_id: 1,
      loading: true,
      noMoreResults: false,
      lastArchived: null,
      conversationIdLastRowValue: "",
      undoMessageOpen: false,
      optOutUndoMessageOpen: false,
      lastOptOut: null,
    });
  };

  getConversations = () => {
    let goal_id = this.props.goal.goal_id;

    fetch(
      `${REACT_APP_AWS_GATEWAY_DOMAIN}/conversation/active?goalid=${goal_id}&limit=${LIMIT}${
        this.state.read === "" ? "" : "&read=" + this.state.read
      }&sortOrder=${this.state.sortOrder}${
        this.state.conversationIdLastRowValue === ""
          ? ""
          : "&conversationIdLastRowValue=" +
            this.state.conversationIdLastRowValue
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        const conversations = this.state.conversations;
        const noMoreResults = response.length === 0;
        let conversationIdLastRowValue = "";
        if (response.length !== 0) {
          conversationIdLastRowValue =
            response[response.length - 1].conversation_id;
        }
        this.setState({
          conversations: [...conversations, ...response],
          loading: false,
          noMoreResults: noMoreResults,
          conversationIdLastRowValue: conversationIdLastRowValue,
        });
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  selectConversation = (conversation) => {
    this.setState({ selected_conversation: conversation });
  };
  deselectConversation = () => {
    this.setState({ selected_conversation: null });
  };

  markAsRead = (conversationId, index) => {
    this.updateReadState(index, true);
    this.updateConversation(conversationId, true, false);
  };

  markAsUnread = (conversationId, index) => {
    this.updateReadState(index, false);
    this.updateConversation(conversationId, false, false);
  };

  markAsArchived = (conversationId, index) => {
    this.updateArchiveState(index, true);
    this.updateConversation(conversationId, false, true);
    this.handleUndoOpen();
  };

  undoArchive = () => {
    this.updateConversation(
      this.state.lastArchived.conversation_id,
      false,
      false
    );
    this.handleUndoClose();
  };

  undoOptOut = () => {
    this.deleteOptOut();
    const conversationList = [...this.state.conversations];
    const lastOptOutIndex = this.state.lastOptOutIndex;
    conversationList.splice(lastOptOutIndex, 0, this.state.lastOptOut);
    this.setState({ conversations: conversationList });
    this.handleUndoClose();
  };

  deleteOptOut = () => {
    const optOutConvo = this.state.lastOptOut;
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/conversation/optout", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        groupid: optOutConvo.group_id,
        contactid: optOutConvo.contact_id,
        phone: optOutConvo.contact_phone,
        conversationId: optOutConvo.conversation_id
      }),
    }).then((response) => {
      if (response.ok) return response.json();
      throw new Error("Network response was not ok.");
    });
  };

  tagAsWrongNumber = (conversation_id, index) => {
    this.createConversationTagByValue(conversation_id, index);
  };

  updateReadState = (index, read) => {
    this.setState({
      conversations: update(this.state.conversations, {
        [index]: { read: { $set: read } },
      }),
    });
  };

  updateArchiveState = (index) => {
    let conversationList = [...this.state.conversations];
    const archivedConvo = conversationList[index];
    conversationList.splice(index, 1);
    this.setState({
      conversations: [...conversationList],
      lastArchived: archivedConvo,
    });
  };

  updateConversation = (conversationId, read, archived) => {
    fetch(`${REACT_APP_AWS_GATEWAY_DOMAIN}/conversation/${conversationId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        read: read,
        archived: archived,
      }),
    }).catch((error) => this.setState({ errorMessage: error.message }));
  };

  updateOptOut = (index) => {
    let conversationList = [...this.state.conversations];
    const optOutConvo = conversationList[index];
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/conversation/optout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        groupid: optOutConvo.group_id,
        contactid: optOutConvo.contact_id,
        phone: optOutConvo.contact_phone,
        conversationId: optOutConvo.conversation_id
      }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        const optOutConvo = conversationList[index];
        conversationList.splice(index, 1);

        this.setState({
          lastOptOutIndex: index,
          conversations: [...conversationList],
          lastOptOut: optOutConvo,
        });
        this.handleOptOutUndoOpen();
      });
  };

  createConversationTagByValue = (conversationId, index) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/tag/create/value", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        conversation_id: conversationId,
        tagValue: "Wrong Number",
      }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.markAsArchived(conversationId, index);
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  handleSortChange = (event) => {
    this.setState(
      {
        conversations: [],
        loading: true,
        sortOrder: event.target.value,
        conversationIdLastRowValue: ""
      },
      this.getConversations
    );
  };

  handleFilterChange = (event) => {
    this.setState(
      {
        conversations: [],
        loading: true,
        read: event.target.value,
        conversationIdLastRowValue: ""
      },
      this.getConversations
    );
  };

  handleUndoOpen = () => {
    this.setState({ undoMessageOpen: true });
  };

  handleOptOutUndoOpen = () => {
    this.setState({ optOutUndoMessageOpen: true });
  };

  handleUndoClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ undoMessageOpen: false, optOutUndoMessageOpen: false });
  };

  render() {
    const {
      conversations,
      selected_conversation,
      noMoreResults,
      sortOrder,
      read,
      loading,
    } = this.state;

    const { classes, auth } = this.props;

    const ShowConversations = conversations.map((convo, index) => {
      return (
        <ConversationCard
          key={convo.conversation_id + "-" + index}
          selectConversation={this.selectConversation}
          conversation={convo}
          index={index}
          auth={auth}
          markAsRead={this.markAsRead}
          markAsUnread={this.markAsUnread}
          markAsArchived={this.markAsArchived}
          tagAsWrongNumber={this.tagAsWrongNumber}
          optoutClick={this.updateOptOut}
        ></ConversationCard>
      );
    });

    const ConversationSorting = (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="past-conversation-sort-order-label">Sort</InputLabel>
          <Select
            id="past-conversation-sort-order"
            value={sortOrder}
            onChange={this.handleSortChange}
          >
            <MenuItem value={"ASC"}>Oldest</MenuItem>
            <MenuItem value={"DESC"}>Newest</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="past-conversation-read-filter-label">
            Filter
          </InputLabel>
          <Select
            id="past-conversation-read-filter"
            value={read}
            onChange={this.handleFilterChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value={true}>Read</MenuItem>
            <MenuItem value={false}>Unread</MenuItem>
          </Select>
        </FormControl>
      </div>
    );

    const undoAction = (
      <Button onClick={this.undoArchive} color="secondary" size="small">
        Undo
      </Button>
    );

    const undoOptOut = (
      <Button onClick={this.undoOptOut} color="secondary" size="small">
        Undo
      </Button>
    );

    //todo - update undo messasge to identify convo to undo instead of two snackbars
    const ConversationSection = (
      <div>
        {ShowConversations}
        {!noMoreResults && (
          <Button onClick={this.getConversations} color="primary">
            Show more
          </Button>
        )}
        <Snackbar
          open={this.state.undoMessageOpen}
          autoHideDuration={6000}
          onClose={this.handleUndoClose}
        >
          <SnackbarContent
            message="You have just archived a message."
            action={undoAction}
          />
        </Snackbar>
        <Snackbar
          open={this.state.optOutUndoMessageOpen}
          autoHideDuration={6000}
          onClose={this.handleUndoClose}
        >
          <SnackbarContent
            message="You have just marked a phone number as opted out."
            action={undoOptOut}
          />
        </Snackbar>
      </div>
    );

    if (selected_conversation) {
      return (
        <PastConversation
          auth={auth}
          conversation_id={selected_conversation.conversation_id}
          goal_id={selected_conversation.goal_id}
        />
      );
    }

    if (conversations.length > 0) {
      return (
        <div>
          {ConversationSorting}
          {ConversationSection}
        </div>
      );
    } else if (loading) {
      return <h1>Loading Conversations...</h1>;
    } else {
      return (
        <div>
          {ConversationSorting}
          <h1>No Conversations...</h1>
        </div>
      );
    }
  }
}

export default withStyles(styles)(ConversationSelect);
