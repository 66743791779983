import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SimpleDialog from "../../Components/SimpleDialog";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = theme => ({
  item: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

class CreateAgentDialog extends Component {
  state = {
    isLoading: false,
    checkedIds: [],
    open: false,
    removeMessage: "Are you sure you want to remove these Agents?",
    agentsToRemove: []
  };

  handleAdd = () => {
    this.props.onClose(this.state.checkedIds, "add");
  };

  handleRemove = choice => {
    this.setState({ open: false });
    if (choice){
      this.props.onClose(this.state.checkedIds, "remove");
    }
  };

  openConfirmation = () => {
    let message = "Are you sure you want to remove these Agents?"
    let checkedAgents = []
    let checkedIds = this.state.checkedIds;
    this.props.existingAgents.forEach( x => {
      if(checkedIds.includes(x.id)){
        checkedAgents.push(x.email);
      }
    });
    this.setState({ agentsToRemove: checkedAgents });
    this.setState({ removeMessage: message });
    this.setState({ open: true });
  };

  handleOnChange = (id) => {
    let checkedIdList = this.state.checkedIds;
    if(checkedIdList.includes(id)){
      const index = checkedIdList.indexOf(id);
      if (index > -1) {
        checkedIdList.splice(index, 1);
      }
    } else {
      checkedIdList = checkedIdList.concat(id);
    }

    this.setState({ checkedIds: checkedIdList });
  };

  toggleAll = (id) => {
    if(this.props.existingAgents.length===this.state.checkedIds.length){
      this.setState({ checkedIds: [] });
    } else {
      this.setState({ checkedIds: this.props.existingAgents.map(function(x) { return x.id; })});
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({ checkedIds: [] });
    }
  }

  render() {
    const { classes, open, onClose } = this.props;

    let existingAgents;
    let addButton = null;
    let removeButton = null;

    if (this.state.isLoading) {
      existingAgents = <CircularProgress className={classes.progress} />;
    } else if (this.props.existingAgents.length > 0) {
      addButton= (
        <Button onClick={this.handleAdd} color="primary" autoFocus>
            Add
        </Button>
      );
      removeButton= (
        <Button onClick={this.openConfirmation} color="primary" autoFocus>
            Remove
        </Button>
      );
      existingAgents = (
        <div>
          <Checkbox 
          style={{"marginLeft":"16px"}}
          checked={this.props.existingAgents.length===this.state.checkedIds.length} 
          onChange={() => this.toggleAll()}/>
          <label style={{"fontWeight":"bold"}}>SELECT ALL</label>
          <div style={{"paddingRight":"30px", "overflow": "auto", "maxHeight": "400px"}}>
          <List id="agentss-list" style={{"listStyleType": "none"}}>
            {this.props.existingAgents.sort(function(a, b) {
              if(a.email.toLowerCase() < b.email.toLowerCase()) return -1;
              if(a.email.toLowerCase() > b.email.toLowerCase()) return 1;
              return 0;
            }).map(({ id, name, email }, index) => {
              return (
                <ListItem  key={index} classes={{ root: classes.item }}>
                  <Checkbox
                    id={id}
                    name={name}
                    value={name}
                    checked={this.state.checkedIds.includes(id)}
                    onChange={() => this.handleOnChange(id)}
                  />
                  <ListItemText id={id} primary={`${email} (${name})`}/>
                </ListItem >
              );
            })}
          </List>
          </div>
        </div>
      );
    } else {
      existingAgents = (
        <Typography variant="body1" style={{"padding":"15px"}}>
          No agents are linked to this organization, or all agents are already linked to this group.
        </Typography>
      );
    }

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog-2"
      >
        <SimpleDialog
          open={this.state.open}
          onClose={this.handleRemove}
          title="Remove Agents"
          message={this.state.removeMessage}
          listItems={this.state.agentsToRemove}
        />
        <DialogTitle id="create-group-dialog-2">Add existing Agents</DialogTitle>
        {existingAgents}
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          {addButton}
          {removeButton}
        </DialogActions>
      </Dialog>
    );
  }
}

CreateAgentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default withStyles(styles)(CreateAgentDialog);
