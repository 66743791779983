import React, { Component } from 'react';
import { Button, CardHeader, CardContent } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { render } from 'react-dom';

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing.unit / 2,
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
});
class AssignTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedTag: "",
        }
    }

    selectOptions = () => this.props.organizationTags.map((tag, index) => {
        return (<MenuItem key={index} value={tag}>{tag.tag}</MenuItem>)
    })

    selectedTags = () => this.props.appliedTags.map((data, index) => {
        return (
            <Chip
                key={data.tag + index}
                label={data.tag}
                onDelete={() => this.props.handleTagDelete(data)}
            />
        );
    });

    selectOrgTags = () => {
        if (this.props.organizationTags.length > 0) {
            return (<FormControl>
                <Select
                    id="demo-simple-select"
                    value={this.state.selectedTag}
                    onChange={this.props.handleTagSelect}
                    disabled={this.state.isLoading}
                    displayEmpty
                    autoWidth
                >
                    {this.selectOptions()}
                </Select>
            </FormControl>)
        }
    }

    showAppliedTags = () => {
        if (this.props.appliedTags.length > 0) {
            return (
                <Paper className={this.props.classes.root}>
                    {this.selectedTags()}
                </Paper>
            )
        }
    }

    render() {

        const { classes } = this.props;



        return (<div style={{ margin: '10px 0px 10px 0px' }}>
            <Card>
                <CardHeader title="Tags"></CardHeader>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            {this.selectOrgTags()}
                        </Grid>
                        <Grid item xs={6}>
                            {this.showAppliedTags()}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div >);
    }
}

export default withStyles(styles)(AssignTags);