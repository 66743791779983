import React from 'react';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress"
import { CardHeader, CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

export default function OptOut(props){

    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState("");


    const optoutClick = () => {
        setIsLoading(true);
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN + "/conversation/optout",
            {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.auth.getAccessToken()}`
                },
                body: JSON.stringify({groupid: props.opt.groupid, contactid: props.opt.contactid, phone: props.opt.phone, conversationId: props.opt.conversationId})
            }
          )
            .then(response => {
              if (response.ok) return response.json();
              throw new Error("Network response was not ok.");
            })
            .then(response => {
                window.location.href = '/goal/select';
                setIsLoading(false);
            })
            .catch(error => setError(error));
            
    }

    return (
        <div style={{margin: '10px 0px 10px 0px'}}>
            <Card>
                <CardHeader title="Optout Conversation"></CardHeader>
                <CardContent>
                    <Button variant="contained" color="primary" onClick={optoutClick}>  
                        {isLoading ? <CircularProgress /> : "Mark as OptOut"}
                    </Button>
                </CardContent>
            </Card>           
        </div>
    )
}