import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { DateTime } from "luxon";

const styles = {
  card: {
    minWidth: 275,
    margin: "15px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

class GoalCard extends Component {
  render() {
    const { goal, classes, selectGoal } = this.props;
    var formattedStartTime = DateTime.fromISO(goal.start_time).toLocaleString(DateTime.DATETIME_SHORT);
    var formattedEndTime = DateTime.fromISO(goal.end_time).toLocaleString(DateTime.DATETIME_SHORT);

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Active Goal
          </Typography>
          <Typography variant="h5" component="h2">
            {goal.name}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Start Date: {formattedStartTime}
            <br />
            End Date: {formattedEndTime}
          </Typography>
          <Typography component="p">
            Description: {goal.description}
            <br />
            Records left: {goal.number_of_conversations}
            <br />
            Records Awaiting Reply:{" "}
            {goal.number_of_received_unread_conversations}
          </Typography>
          {goal.mms && <Typography variant="h5">MMS</Typography>}
        </CardContent>
        <CardActions>
          <Button
            size="large"
            variant="contained"
            disabled={goal.number_of_conversations <= 0}
            onClick={() => selectGoal(goal, true)}
          >
            Send Messages
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => selectGoal(goal, false)}
          >
            Manage Messages
          </Button>
        </CardActions>
      </Card>
    );
  }
}

GoalCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoalCard);
