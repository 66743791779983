import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export default function AdditionalScripts(props) {
  const calcSms = (count) => {
    if (count === 0) {
      return "0/0";
    }
    let hold =
      count +
      "/" +
      (Math.floor(count / 160) === 0 ? 1 : Math.ceil(count / 160));
    return hold;
  };

  const ListScripts = props.scripts.map((script, index) => {
    return (
      <Grid item xs={4} key={script.id}>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          fullWidth={true}
          onChange={props.handleTitleChange(script)}
        />
        <TextField
          id="filled-multiline-flexible-new-contacts"
          label="Script Body"
          multiline
          rowsMax="6"
          onChange={props.handleBodyChange(script)}
          margin="normal"
          variant="outlined"
          helperText={calcSms(script.body.length)}
          fullWidth={true}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.removeScript(index);
          }}
        >
          Remove
        </Button>
      </Grid>
    );
  });

  return (
    <div>
      <Grid container>
        <Grid item xs={2}>
          <h1>Additional Scripts</h1>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.createScript}
            disabled={props.isLoading}      
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={8}></Grid>
      </Grid>
      {ListScripts.length > 0 && (
        <Grid container spacing={32}>
          {ListScripts}
        </Grid>
      )}
    </div>
  );
}
