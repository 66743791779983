import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import CreateContactDialog from "./CreateContactDialog";
import MUIDataTable from "mui-datatables";
import Grid from "@material-ui/core/Grid";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px 0px 20px 0px",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  buttonMargin: {
    marginTop: theme.spacing.unit,
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px",
  },
});

class Contacts extends Component {
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          head: {
            fontWeight: "bold",
            fontSize: "16px",
          },
        },
      },
    });

  state = {
    open: false,
    contacts: [],
    isLoading: false,
    limit: 10,
    offset: 0,
    contactCount: 0,
    page: 0,
    rowsPerPage: 10,
  };

  componentDidMount() {
    this.getContacts(this.state.limit, this.state.offset);
  }

  getContacts = (limit, offset) => {
    let { id } = this.props.group_id;
    this.setState({ isLoading: true });
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN +
        "/contact/get/" +
        id +
        "?limit=" +
        limit +
        "&offset=" +
        offset,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({
          contacts: response.contacts,
          contactCount: parseInt(response.count),
          isLoading: false,
        });
        this.props.set_tab_count(response.count);
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  getContactsForDownload = () => {
    let { id } = this.props.group_id;
    return fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/getcontactsdownload/" + id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    ).then((response) => {
      return response.json();
    });
  };

  searchContacts = (text, limit, offset) => {
    let { id } = this.props.group_id;
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN +
        "/contact/search/" +
        id +
        "?limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=" +
        text,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({
          contacts: response.contacts,
          contactCount: parseInt(response.count),
        });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  CreateContactButtonClick = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (contactToAdd) => {
    if (!contactToAdd.target) {
      this.getContacts(this.state.limit, this.state.offset);
    }
    this.setState({ open: false });
  };

  downloadContactsCSV = (contacts) => {
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(contacts[0]);
    const csv = [
      header.join(","), // header row first
      ...contacts.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");

    return csv;
  };

  onDownload = (buildHead, buildBody, columns, data) => {
    this.getContactsForDownload().then((response) => {
      var csv = this.downloadContactsCSV(response);
      this.downloadCSV(csv, "contacts.csv");
    });
    return false;
  };

  downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: "text/csv" });

    /* taken from react-csv */
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const dataURI = `data:text/csv;charset=utf-8,${csv}`;

      const URL = window.URL || window.webkitURL;
      const downloadURI =
        typeof URL.createObjectURL === "undefined"
          ? dataURI
          : URL.createObjectURL(blob);

      let link = document.createElement("a");
      link.setAttribute("href", downloadURI);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  markContactInactive = (contactId, _callback) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/deactivate/" + contactId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
    })
      .then(
        (result) => {
          if (result.ok) {
            _callback();
          }
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  pageButtonClicked = (pageNumber) => {
    if (pageNumber > this.state.page) {
      var newOffset = this.state.offset + this.state.limit;
    } else {
      var newOffset = this.state.offset - this.state.limit;
    }

    this.setState({ offset: newOffset, page: pageNumber, isLoading: true });
    this.getContacts(this.state.limit, newOffset);
  };

  rowNumberChanged = (rowNumber) => {
    this.setState({ limit: rowNumber });
    this.getContacts(rowNumber, this.state.offset);
    return rowNumber;
  };

  searchClose = () => {
    this.setState({ offset: 0, page: 0 });
    this.searchContacts("", this.state.limit, this.state.offset);
  };

  searchChange = (textSearch) => {
    textSearch = textSearch == null ? "" : textSearch;
    this.searchContacts(textSearch, this.state.limit, this.state.offset);
    return textSearch;
  };

  render() {
    const { classes, auth } = this.props;
    const { id } = this.props.group_id;
    const { contacts, isLoading, open, limit, offset } = this.state;

    const columns = [
      {
        name: "first_name",
        label: "First Name",
        options: {
          sort: true,
        },
      },
      {
        name: "last_name",
        label: "Last Name",
        options: {
          sort: true,
        },
      },
      {
        name: "phone",
        label: "Phone",
        options: {
          sort: true,
        },
      },
      {
        name: "contact_id",
        label: "Delete",
        options: {
          download: true,
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                aria-label="Delete"
                onClick={() =>
                  this.markContactInactive(
                    value,
                    this.getContacts(limit, offset)
                  )
                }
              >
                <RemoveCircleIcon color="error" />
              </Button>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      print: false,
      selectableRows: false,
      onChangePage: this.pageButtonClicked,
      onChangeRowsPerPage: this.rowNumberChanged,
      count: this.state.contactCount,
      page: this.state.page,
      serverSide: true,
      rowsPerPage: this.state.rowsPerPage,
      rowsPerPageOptions: [],
      download: true,
      filter: false,
      jumpToPage: true,
      onSearchClose: this.searchClose,
      onDownload: this.onDownload,
      onSearchChange: this.searchChange,
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.pageButtonClicked(tableState.page);
            break;
          default:
            console.log("action not handled.");
        }
      },
    };

    let contactsTable;

    if (isLoading) {
      contactsTable = <CircularProgress className={classes.progress} />;
    } else {
      contactsTable = (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={"Manage Contacts"}
            data={contacts}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      );
    }

    return (
      <div className={classes.root}>
        <CreateContactDialog
          open={open}
          onClose={this.handleClose}
          groupId={id}
          auth={auth}
        />
        <Grid container spacing={0} className={classes.rowSpacing}>
          <Grid item xs={8} />
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.CreateContactButtonClick}
            >
              Create a Contact
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              href={"/massimport/" + id}
            >
              Mass Import
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {contactsTable}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contacts);
