import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Dropzone, FileItem } from "@dropzone-ui/react";

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
});

function MMS(props) {
  const { classes, upload, files, onFileDelete } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <h1>MMS</h1>
        </Grid>
      </Grid>
      <Typography variant="body1" gutterBottom>
        Click the button to upload an image. The Image will be included with the
        script when sending.
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Dropzone
            onChange={upload}
            maxFiles={1}
            maxFileSize={768000}
            accept="image/png,image/jpeg,video/mp4"
            value={files}
          >
            {files.map((file) => (
              <FileItem {...file} onDelete={onFileDelete} preview />
            ))}
          </Dropzone>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(MMS);
