import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import OptOut from "./OptOut";
import MessageChat from "../AgentConversations/MessageChat";
import ScriptSelection from "./ScriptSelection";
import AssignTags from "./AssignTags";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px"
  }
});

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;
var yesScript = "";
var noScript = "";

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupid: null,
      contactid: null,
      toPhone: null,
      current_conversation: null,
      messages: [],
      isLoading: true,
      messageToSend: "",
      scripts: [],
      selectedScript: "",
      isScriptsLoading: false,
      isYesScript: false,
      isNoScript: false,
      organizationTags: [],
      appliedTags: [],
      noMoreConversations: false,
      sortOrder: "ASC",
      read: false,
      orgTags: null,
    };
  }

  componentWillMount() {
    this.setState({ isLoading: false });
    this.getPastConversation();
    this.getOrganizationTags();
  }

  getPastConversation = (conversation_id) => {
    this.setState({ isLoading: true });

    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN +
        "/conversation/" +
        (conversation_id ? conversation_id : this.props.conversation_id),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.setState({
            toPhone: response[0].to_phone,
            groupid: response[0].group_id,
            contactid: response[0].contact_id,
            current_conversation: response[0],
            isLoading: false,
          });
          this.getScripts();
          this.refreshMessages();
        } else {
          this.setState({ current_conversation: null, isLoading: false });
        }
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  getNextConversation = () => {
    this.setState({ isLoading: true });
    fetch(
      `${REACT_APP_AWS_GATEWAY_DOMAIN}/conversation/active?goalid=${
        this.props.goal_id
      }&limit=${1}${"&read=" + this.state.read}&sortOrder=${
        this.state.sortOrder
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response.length !== 0) {
          this.getPastConversation(response[0].conversation_id);
          this.getGoalConversationTags(
            this.state.orgTags,
            response[0].conversation_id
          );
        } else {
          this.setState({ noMoreConversations: true, isLoading: false });
        }
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  sendMessage = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/message/send/past", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        contact_id: this.state.contactid,
        message: this.state.messageToSend,
        goal_id: this.props.goal_id,
      }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.setState({ messageToSend: "" });
          this.getNextConversation();
        }
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  refreshMessages = () => {
    this.setState({ isLoading: true });
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN +
        "/conversation/messages/" +
        this.state.current_conversation.conversation_id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({ messages: response, isLoading: false });
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  getScripts = () => {
    this.setState({ isScriptsLoading: true });
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/scripts/get/" + this.props.goal_id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          let indexYes = response.findIndex((item) => item.type === "yes");
          let yesExist = false;
          let noExist = false;
          if (indexYes != -1) {
            yesScript = response[indexYes];
            response.splice(indexYes, 1);
            yesExist = true;
          }
          let indexNo = response.findIndex((item) => item.type === "no");
          if (indexNo != -1) {
            noScript = response[indexNo];
            response.splice(indexNo, 1);
            noExist = true;
          }
          response.unshift({ body: "", title: "None", id: -1 });
          this.setState({
            scripts: response,
            isScriptsLoading: false,
            selectedScript: "",
            isYesScript: yesExist,
            isNoScript: noExist,
          });
        }
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  handleYesScriptClick = () => {
    this.setState({ messageToSend: yesScript.body });
  };

  handleNoScriptClick = () => {
    this.setState({ messageToSend: noScript.body });
  };

  handleSelectScriptChange = (event) => {
    this.setState({
      selectedScript: event.target.value,
      messageToSend: event.target.value,
    });
  };

  getOrganizationTags = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/tags/get/active", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.setState({ orgTags: response });
          return this.getGoalConversationTags(response);
        }
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  getGoalConversationTags = (orgTags, conversation_id) => {
    fetch(
      REACT_APP_AWS_GATEWAY_DOMAIN +
        "/goals/conversation/tag?conversation_id=" +
        (conversation_id ? conversation_id : this.props.conversation_id),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          this.setState({ isLoading: false });
          return this.calculateValues(orgTags, response);
        }
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  calculateValues = (orgTags, convoTags) => {
    let orgTagsDiff = orgTags.filter(function (x) {
      return (
        convoTags.filter(function (y) {
          return x.tag_id === y.tag_id;
        }).length == 0
      );
    });

    orgTagsDiff.sort(function (a, b) {
      const textA = a.tag.toUpperCase();
      const textB = b.tag.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    convoTags.sort(function (a, b) {
      const textA = a.tag.toUpperCase();
      const textB = b.tag.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    this.setState({ organizationTags: orgTagsDiff, appliedTags: convoTags });
  };

  createConversationTag = (tagid, conversationId) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/tag/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        conversation_id: conversationId,
        tagid: tagid,
      }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {})
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  deleteConversationTag = (tagid, conversationId) => {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/goals/tag/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        conversation_id: conversationId,
        tagid: tagid,
      }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {})
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  handleTagSelect = (event) => {
    const tags = this.state.appliedTags;
    this.createConversationTag(
      event.target.value.tag_id,
      this.state.current_conversation.conversation_id
    );
    tags.push(event.target.value);
    let orgTags = this.state.organizationTags;
    const diff = orgTags.filter((x) => {
      return x.tag_id !== event.target.value.tag_id;
    });
    this.state.organizationTags = diff;
    this.state.appliedTags = tags;
    this.setState({ organizationTags: diff, appliedTags: tags });
  };

  handleTagDelete = (data) => {
    const chipData = this.state.appliedTags;
    const chipToDelete = chipData.findIndex(
      (item) => item.tag_id == data.tag_id
    );
    this.deleteConversationTag(
      data.tag_id,
      this.state.current_conversation.conversation_id
    );
    chipData.splice(chipToDelete, 1);
    var orgTags = this.state.organizationTags;
    orgTags.push(data);
    orgTags.sort(function (a, b) {
      var textA = a.tag.toUpperCase();
      var textB = b.tag.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    this.setState({ appliedTags: chipData, organizationTags: orgTags });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleSend = () => {
    this.sendMessage();
  };

  calcSms = (count) => {
    if (count === 0) {
      return "0/0";
    }
    let hold =
      count +
      "/" +
      (Math.floor(count / 160) === 0 ? 1 : Math.ceil(count / 160));
    return hold;
  };

  render() {
    const { classes, history } = this.props;
    let {
      isLoading,
      messageToSend,
      groupid,
      contactid,
      toPhone,
      organizationTags,
      appliedTags,
      noMoreConversations,
    } = this.state;

    let chat = (
      <Grid container>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={9}>
            <MessageChat messages={this.state.messages} />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={7}>
            <TextField
              id="outlined-multiline-flexible"
              label="Send Message"
              multiline={true}
              fullWidth={true}
              value={messageToSend}
              onChange={this.handleChange("messageToSend")}
              margin="normal"
              variant="outlined"
              autoFocus={true}
              helperText={this.calcSms(messageToSend.length)}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Grid>
    );

    let chatButtons = (
      <Grid container>
        <Grid item xs={11}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            className={classes.buttonRight}
            onClick={this.sendMessage}
          >
            Send
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            className={classes.buttonRight}
            onClick={this.refreshMessages}
          >
            refresh
          </Button>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    );

    if (this.state.isLoading) {
      return <h1>Loading Conversations...</h1>;
    } else if (noMoreConversations) {
      return (
        <div>
          <h1>No more unread conversations.</h1>
        </div>
      );
    } else {
      return (
        <div>
          <Grid container>
            <Grid item xs={9}>
              {chatButtons}
              {chat}
            </Grid>
            <Grid item xs={3}>
              <ScriptSelection
                isYesScript={this.state.isYesScript}
                isNoScript={this.state.isNoScript}
                isScriptsLoading={this.state.isScriptsLoading}
                selectedScript={this.state.selectedScript}
                scripts={this.state.scripts}
                handleYesScriptClick={this.handleYesScriptClick}
                handleNoScriptClick={this.handleNoScriptClick}
                handleSelectScriptChange={this.handleSelectScriptChange}
              ></ScriptSelection>

              <OptOut
                auth={this.props.auth}
                opt={{ groupid: groupid, contactid: contactid, phone: toPhone, conversationId: this.state.current_conversation.conversation_id}}
              />
              <AssignTags
                handleTagSelect={this.handleTagSelect}
                handleTagDelete={this.handleTagDelete}
                appliedTags={appliedTags}
                organizationTags={organizationTags}
                auth={this.props.auth}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default withStyles(styles)(Conversation);
