import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    emailError: false,
  },
});

class AddOptOutDialog extends Component {
  state = {
    phone: null,
  };

  handleCreate = () => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (this.state.phone.match(phoneno)) {
      this.props.onClose(this.state.phone);
    }
  };

  handleClose = () => {
    this.props.onClose('')
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog"
      >
        <DialogTitle id="create-group-dialog">Opt-Out</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            id="phone"
            label="Phone Number"
            className={classes.textField}
            onChange={this.handleChange("phone")}
            margin="normal"
            name="phone"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCreate} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddOptOutDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(AddOptOutDialog);
