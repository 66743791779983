import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import CircularProgress from "@material-ui/core/CircularProgress";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`
  },
  groupListItem: {
    cursor: "pointer"
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`
  },
  column: {
    padding: theme.spacing.unit * 2
  },
  rowSpacing: {
    padding: "20px 0px 20px 0px"
  },
  textPadding: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  removeButtonMargin: {
    marginBottom: "20px"
  },
  settingTitle: {
    marginTop: "20px"
  },
  buttonRight: {
    float: "right",
    marginLeft: "20px"
  }
});

class GroupDashboard extends Component {
  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
            fontWeight: "bold",
            fontSize: "16px" 
        }
      }
    }
  })

  state = {
    open: false,
    isLoading: false,
    reports: [],
    goalStatsLoading: false,
    goalStats: [],
    reportClick: false,
    reportsLoading: false
  };

  componentDidMount() {
    this.getReports(false);
    this.getGoalStats();
  }

  getReports = (click) => {
    //need to finish
    let { id } = this.props.group_id;
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/reports/group/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`
      }
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(response =>
        this.setState({
          reports: response,
          isLoading: false,
          reportClick: click
        })
      )
      .catch(error => this.setState({ message: error.message }));
  };

  reportClick = () => {
    this.generateReports();
  }

  generateReport = () => {
    let { id } = this.props.group_id;
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/reports/group/message/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`
      }
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(() => {
        this.getReports(true);
      })
      .catch(error => this.setState({ message: error.message }));
  };

  getGoalStats = async () => {
    let { id } = this.props.group_id;
    this.setState({ goalStatsLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/goal/delivery/stats/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`
      }
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(response => {
        this.setState({ goalStats: response, goalStatsLoading: false });
      })
      .catch(error => this.setState({ message: error.message }));
  }

  refreshReports = () => {
    this.getReports();
  };

  generate = element => {
    return this.state.reports.map(value =>
      React.cloneElement(element, {
        key: value
      })
    );
  };

  render() {
    const { classes } = this.props;
    const { reports } = this.state;

    let reportButtons = (
      <div>
        <Grid container className={classes.rowSpacing}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.generateReport}
              className={classes.buttonRight}
            >
              Generate Report
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.refreshReports}
              className={classes.buttonRight}
            >
              refresh
            </Button>
          </Grid>
        </Grid>
      </div>
    );



    let reportList = (
      <List>
        {reports.map(value => (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <a href={value.url_path}>
              <ListItemText primary={value.name}></ListItemText>
            </a>
          </ListItem>
        ))}
      </List>
    );
    var reportSection;
    if (this.state.isLoading) {
      reportSection = (
        <div>
          {reportButtons}
          {<h3>Loading Reports...</h3>}
        </div>
      );
    } else if (this.state.reportClick && !this.state.isLoading){
      reportSection = (
        <div>
          {reportButtons}
          <h3>Your report notification will be sent by email.</h3>
        </div>
      );
    } else {
      reportSection = (
        <div>
          {reportButtons}
          {reportList}
        </div>
      );
    }

    const columns = [
      {
        name: "goal_name",
        label: "Goal Name",
        options: {
          sort: true,
        }
      },
      {
        name: "start_time",
        label: "Start Time",
        options: {
          sort: true,
        }
      },
      {
        name: "end_time",
        label: "End Time",
        options: {
          sort: true,
        }
      },
      {
        name: "total_count",
        label: "Total Texts",
        options: {
          sort: true,
        }
      },
      {
        name: "count_sent",
        label: "Blu-Txt sent",
        options: {
          sort: true,
        }
      },
      {
        name: "count_remaining",
        label: "Blu-Txt remaining",
        options: {
          sort: true,
        }
      },
      {
        name: "carrier_sent",
        label: "Carrier sent",
        options: {
          sort: true,
        }
      },
      {
        name: "count_failed",
        label: "Failed",
        options: {
          sort: true,
        }
      },
      {
        name: "rejected_count",
        label: "Rejected",
        options: {
          sort: true,
        }
      },
      {
        name: "undelivered_count",
        label: "Undelivered",
        options: {
          sort: true,
        }
      },
      {
        name: "delivered_count",
        label: "Delivered",
        options: {
          sort: true,
        }
      }
    ];

    const options = {
      filterType: "checkbox",
      print: false,
      selectableRows: false,
      download: true,
      filter: false,
      jumpToPage: true,
    };

    let goalStats;

    if (this.state.goalStatsLoading) {
      goalStats = <CircularProgress className={classes.progress} />;
    } else {
      goalStats = (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={"Metrics"}
            data={this.state.goalStats}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      );
    }


    return (<div>
      <Grid container>
        <Grid item xs={12}>
          {reportSection}
        </Grid>
        <Grid item xs={12}>
          {goalStats}
        </Grid>
      </Grid>
    </div>)

  }
}

GroupDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupDashboard);
