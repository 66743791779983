import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import States from "./states.json";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
  },
});

class CreateGroupDialog extends Component {
  state = {
    name: "",
    city: "",
    state: "",
    nameRequired: false,
    cityRequired: false,
    stateRequired: false,
    clientRequired: false,
    client: "",
    clients: [],
  };

  componentDidMount() {
    this.getOrganizationClients();
  }

  getOrganizationClients = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/client/get", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          result.push({"id": -1, "name": "None"})
          this.setState({
            clients: result,
            isLoading: false,
          });
        },
        (error) => {
          console.log("error:" + error);
        }
      );
  };

  handleCreate = () => {
    let group = {
      name: this.state.name,
      city: this.state.city,
      state: this.state.state,
      client_id: this.state.client === -1 ? "" : this.state.client,
    };
    this.setState({
      nameRequired: false,
      stateRequired: false,
      cityRequired: false,
      clientRequired: false
    });
    var nameR = false;
    var cityR = false;
    var stateR = false;
    var clientR = false;

    if (this.state.name === "") {
      nameR = true;
      this.setState({ nameRequired: true });
    }
    if (this.state.city === "") {
      cityR = true;
      this.setState({ cityRequired: true });
    }
    if (this.state.state === "") {
      stateR = true;
      this.setState({ stateRequired: true });
    }
    if(this.state.client === ""){
      clientR = true;
      this.setState({clientRequired: true});
    }
    if (nameR === false && cityR === false && stateR === false && clientR === false) {
      this.setState({ state: "" });
      this.props.onClose(group);
    }
    
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleStateChange = (event) => {
    this.setState({ state: event.target.value });
  };

  handleClientChange = (event) => {
    this.setState({ client: event.target.value });
  };

  onCancel = () => {
    this.setState({ state: "" });
    this.props.onClose(null);
  };

  renderOptions() {
    return States.map((dt, i) => {
      //console.log(dt);
      return (
        <MenuItem
          label="Select a country"
          value={dt.name}
          key={i}
          name={dt.name}
        >
          {dt.name}
        </MenuItem>
      );
    });
  }

  renderClientOptions() {
    return this.state.clients.map((dt, i) => {
      //console.log(dt);
      return (
        <MenuItem label="Select a Client" value={dt.id} key={i} name={dt.name}>
          {dt.name}
        </MenuItem>
      );
    });
  }

  render() {
    const { classes, open, onClose } = this.props;
    const {
      clients,
      cityRequired,
      state,
      stateRequired,
      nameRequired,
      client,
      clientRequired
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog"
      >
        <DialogTitle id="create-group-dialog">Create a Group</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField
                autoFocus
                required
                error={nameRequired}
                id="create-group-name"
                label="Name"
                onChange={this.handleChange("name")}
                margin="normal"
                name="group.name"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                error={cityRequired}
                required
                id="create-group-city"
                label="City"
                margin="normal"
                onChange={this.handleChange("city")}
                name="group.city"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="country-select">State</InputLabel>
              <Select
                value={state}
                error={stateRequired}
                required
                onChange={this.handleStateChange}
                input={<Input id="country-select" />}
              >
                {this.renderOptions()}
              </Select>
            </FormControl>            
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="client-select">Client</InputLabel>
                <Select
                  value={client}
                  error={clientRequired}
                  required
                  onChange={this.handleClientChange}
                  input={<Input id="client-select" />}
                >
                  {this.renderClientOptions()}
                </Select>
              </FormControl>            
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCreate} color="primary" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateGroupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(CreateGroupDialog);
