import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import Typography from "@material-ui/core/Typography";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
  },
});

class NgpVanContactImport extends Component {
  state = {
    savedListId: null,
    isLoading: true,
    loadingMessage: null,
    hasToken: null,
    displayModal: false,
  };

  componentWillMount = () => {
    this.checkIntegrationToken();
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  importList = () => {
    this.setState({
      loadingMessage: "Requesting data from NGP Van.",
      isLoading: true,
    });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/npgvan/export", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify({
        savedListId: this.state.savedListId,
        groupId: this.props.match.params.id,
      }),
    })
      .then(
        (result) => {
          if (result.ok) {
            this.setState({
              loadingMessage: null,
              isLoading: false,
              displayModal: true,
            });
          }
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  checkIntegrationToken = () => {
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/organization/npgvan/token/exist", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        if (response) {
          if (response.length > 0) {
            this.setState({ isLoading: false, hasToken: true });
          } else {
            this.setState({ isLoading: false, hasToken: false });
          }
        }
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  closeModal = () => {
    this.setState({ displayModal: false, savedListId: "" });
  };

  render() {
    const { classes } = this.props;
    const { isLoading, hasToken, loadingMessage, displayModal, savedListId } =
      this.state;

    let contentArea;
    if (hasToken === true) {
      contentArea = (
        <>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Typography className={classes.formControl} variant="h6">
                Please enter a saved list id from the NGP Van system.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={16} justify="center">
            <Grid item>
              {" "}
              <form className={classes.container} noValidate autoComplete="off">
                <TextField
                  id="ngp-van-list-id"
                  label="Saved List Id"
                  value={savedListId}
                  className={classes.textField}
                  onChange={this.handleChange("savedListId")}
                  style={{ margin: "10px" }}
                  name="savedListId"
                />
                <Button
                  onClick={this.importList}
                  color="primary"
                  style={{ margin: "10px" }}
                  variant="contained"
                  autoFocus
                >
                  Import
                </Button>
              </form>
            </Grid>
          </Grid>
        </>
      );
    } else if (hasToken === false) {
      contentArea = (
        <Grid container spacing={16} justify="center">
          <Grid item>
            <Typography className={classes.formControl} variant="h6">
              You must set up an integration on the Organization Settings page
              before being able to import contacts from NGP Van.
            </Typography>
          </Grid>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Button variant="outlined" color="primary" href={"/settings"}>
                Organization Settings
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <div>
        <Dialog open={displayModal}>
          <Card>
            <CardContent>
              <h3>
                Your import has been successfully requested from NGP Van. You
                will receive an email when your contacts have been imported.
              </h3>
            </CardContent>
            <CardActions>
              <Button onClick={this.closeModal}>OK</Button>
            </CardActions>
          </Card>
        </Dialog>
        <LoadingOverlay
          active={isLoading}
          spinner={true}
          text={loadingMessage}
          styles={{
            wrapper: {
              position: "static",
            },
          }}
        ></LoadingOverlay>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <h1>NGP Van Import</h1>
          </Grid>
          {contentArea}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NgpVanContactImport);
