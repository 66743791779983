import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Contacts from "../AdminContact/Contacts";
import Paper from "@material-ui/core/Paper";
import Agents from "../AdminAgents/Agents";
import Goals from "../AdminGoals/Goals";
import PrivateTab from "../../PrivateTab";
import Typography from "@material-ui/core/Typography";
import GroupDashboard from "../AdminGroupDashboard/GroupDashboard";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginLeft: 24,
    marginRight: 24
  }
});


class GroupTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      agentCount: 0,
      contactCount: 0,
      goalCount: 0
    };
    this.updateContactCount = this.updateContactCount.bind(this);
    this.updateAgentCount = this.updateAgentCount.bind(this);
    this.updateGoalCount = this.updateGoalCount.bind(this);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  updateContactCount = (value) => {
    this.setState({ contactCount: value });
  };

  updateAgentCount = (value) => {
    this.setState({ agentCount: value });
  };

  updateGoalCount = (value) => {
    this.setState({ goalCount: value });
  };

  componentDidMount() {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/counts/" + this.props.group_id.id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`
      }
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(response => {
        this.setState({
          agentCount: response[0].agent_count,
          contactCount: response[0].contact_count,
          goalCount: response[0].goal_count
        });
      })
      .catch(error => this.setState({ message: error.message }));
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>
        <Paper className={classes.root}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={"Contacts (" + this.state.contactCount + ")"} />
            <Tab label={"Agents (" + this.state.agentCount + ")"} />
            <Tab label={"Goals (" + this.state.goalCount + ")"} />
            <Tab label="Metrics" />
          </Tabs>
        </Paper>
        {value === 0 && (
          <PrivateTab component={Contacts} group_id={this.props.group_id} set_tab_count={this.updateContactCount}/>
        )}
        {value === 1 && (
          <PrivateTab component={Agents} group_id={this.props.group_id} set_tab_count={this.updateAgentCount}/>
        )}
        {value === 2 && (
          <PrivateTab component={Goals} group_id={this.props.group_id} set_tab_count={this.updateGoalCount}/>
        )}
        {value === 3 && (
          <PrivateTab
            component={GroupDashboard}
            group_id={this.props.group_id}
          ></PrivateTab>
        )}
      </div>
    );
  }
}

GroupTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupTabs);
