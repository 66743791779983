import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ExcelTemplate from "../../TemplateFiles/ImportTemplate.xlsx";
import CsvTemplate from "../../TemplateFiles/ImportTemplate.csv";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";

class SelectDownloadDialog extends Component {
  render() {
    const { open, closeDialog } = this.props;
    return (
      <Dialog
        title="Template Select"
        modal={false}
        open={open}
        disableClose={false}
      >
        <DialogTitle id="download-template-dialog">
          {" "}
          Import Templates
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a file format for importing
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            onClick={closeDialog}
            download={"ImportTemlate.csv"}
            color={"primary"}
            variant={"contained"}
            href={CsvTemplate}
          >
            Download CSV
          </Button>
          <Button
            onClick={closeDialog}
            download={"ImportTemplate.xlsx"}
            color={"primary"}
            variant={"contained"}
            href={ExcelTemplate}
          >
            Download Excel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SelectDownloadDialog;
