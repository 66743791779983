import React from 'react';
import { Button, CardHeader, CardContent } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';

export default function ScriptSelection(props) {


    const selectOptions = props.scripts.map((script, index) => {
        return (<MenuItem key={index} value={script.body}>{script.title}</MenuItem>)
    })



    return (
        <div style={{margin: '10px 0px 10px 0px'}}>
            <Card>
                <CardHeader title="Script Response"></CardHeader>
                <CardContent>
                    <Grid container style={{ margin: '0px 0px 10px 0px' }}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" style={{ marginRight: "20px"}} disabled={props.isScriptsLoading || !props.isYesScript} onClick={props.handleYesScriptClick}>Yes</Button>
                            <Button variant="contained" color="primary" style={{ marginRight: "20px"}} disabled={props.isScriptsLoading || !props.isNoScript} onClick={props.handleNoScriptClick}>No</Button>
                        </Grid>
                    </Grid>
                    <Grid container>                        
                        <Grid item xs={8}>
                            <FormControl>
                                <Select
                                    id="demo-simple-select"
                                    value={props.selectedScript}
                                    onChange={props.handleSelectScriptChange}
                                    disabled={props.isScriptsLoading}
                                    displayEmpty
                                    autoWidth
                                >
                                    {selectOptions}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </CardContent>
            </Card>


        </div>
    );
}