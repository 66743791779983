import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleDialog from '../../Components/SimpleDialog';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const unique = (value, index, self) => {
    return self.indexOf(value) === index
}

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2
    }
});

class TagVanMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationTags: [],
            isLoading: false,
            npgvanData: [],
            npgvanDataCategories: []

        }
    }

    componentWillMount = () => {        
        this.getOrganizationTags();
    }

    getOrganizationTags = () => {
        this.setState({ isLoading: true });
        return fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/tags",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                }
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    this.getNpgVanData(response);
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }


    getNpgVanData = (organizationTags) => {
        this.setState({ isLoading: true });
        return fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/mapping/get",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                }
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                if (response) {
                    let npgVanCats = response.map(function(item){
                        return { SyncDataTypeId: item.sync_data_type, DataType: item.npg_type,  }
                    });
                   // npgVanCats = npgVanCats.filter(unique);
                    npgVanCats = Array.from(new Set(npgVanCats.map(x=>x.SyncDataTypeId))).map(
                        id => {
                            return  {
                                SyncDataTypeId: id,
                                DataType: npgVanCats.find(s => s.SyncDataTypeId === id).DataType
                            };
                        }
                    );
                    
                    organizationTags.forEach(item =>  {
                        var responseCatObj = response.find(s => s.organization_tag_id === item.tag_id);
                        var responeObj = response.find(s => s.organization_tag_id === item.tag_id);
                        item.SelectedCategory = responseCatObj ? responseCatObj.sync_data_type : 0;
                        item.SelectedResponse = responeObj ? responeObj.response_id : 0;                        
                    });
                    this.setState({organizationTags: organizationTags, npgvanData: response, npgvanDataCategories: npgVanCats, isLoading: false})
                }
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    

    createUpdateNpgVanDataMap = (orgTag) => {
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/mapping/create",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    organizationTagId: orgTag.tag_id,
                    npgvanDataResponseId: orgTag.SelectedResponse
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }

    deleteMapping = (orgTag) => {
        fetch(
            REACT_APP_AWS_GATEWAY_DOMAIN +
            "/organization/npgvan/mapping/delete",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.auth.getAccessToken()}`
                },
                body: JSON.stringify({
                    organizationTagId: orgTag
                })
            }
        )
            .then(response => {
                if (response.ok) return response.json();
                throw new Error("Network response was not ok.");
            })
            .then(response => {
               
            })
            .catch(error => this.setState({ errorMessage: error.message }));
    }


    handleCategoryChange = (row,event) => {
        if(event.target.value === 0){
            this.deleteMapping(row.tag_id);
            row.SelectedCategory = event.target.value;
            row.SelectedResponse = 0;
        }else{
            row.SelectedCategory = event.target.value;
        }       
        this.forceUpdate();
    }

    handleResponseChange = (row, event) => {
        
        if(event.target.value === 0){
            this.deleteMapping(row.tag_id);
            row.SelectedResponse = event.target.value;

        }else{
            row.SelectedResponse = event.target.value;
            this.createUpdateNpgVanDataMap(row);
        }        
        this.forceUpdate();
    }

    render() {

        const { classes } = this.props;

        const options = {
            filterType: "checkbox",
            print: false,
            selectableRows: false
        };

        const columns = [
            {
                name: "tag",
                label: "Tag",
                options: {
                    sort: true
                }
            },
            {
                name: "is_active",
                label: "Is Active",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        console.log(tableMeta);
                        let row = this.state.organizationTags[tableMeta.rowIndex];
                        if (value) {
                            return (<span>Active</span>)
                        } else {
                            return (<span>Inactive</span>)
                        }
                    }
                }
            },
            {
                name: "tag",
                label: "NPG Van Category",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        console.log(tableMeta);
                        let row = this.state.organizationTags[tableMeta.rowIndex];
                        if(this.state.npgvanDataCategories && this.state.npgvanDataCategories.length > 0){
                            return (<FormControl variant="outlined">
                            <Select
                                id="demo-simple-select-outlined"
                                value={row.SelectedCategory}                        
                                onChange={(e) => this.handleCategoryChange(row, e)}
                                displayEmpty
                            >
                                <MenuItem key={tableMeta.rowIndex} value={0}>None</MenuItem>
                                {this.state.npgvanDataCategories.map((item,index) => {
                                    return (
                                        <MenuItem key={item.DataType + index + tableMeta.rowIndex} value={item.SyncDataTypeId}>
                                            {item.DataType}
                                        </MenuItem>
                                    );
                                })}

                             </Select>
                         </FormControl>)
                        }else{
                            return (<div></div>);
                        }
                       
                     }
                 }
            },
            {
                name: "tag_id",
                label: "Mapped Response",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let row = this.state.organizationTags[tableMeta.rowIndex];
                        if(row.SelectedCategory && this.state.npgvanData && this.state.npgvanData.length > 0){
                            
                            let filteredResponse = this.state.npgvanData.filter(function(item){
                                return item.sync_data_type == row.SelectedCategory
                            });

                            return (<FormControl variant="outlined">
                            <Select
                                id="demo-simple-select-outlined"
                                value={row.SelectedResponse}
                                onChange={(e) => this.handleResponseChange(row, e)}
                            >
                                <MenuItem key={tableMeta.rowIndex} value={0}>None</MenuItem>
                                {filteredResponse.map((item,index) => {
                                    if(item.response_data && item.response_data != null){
                                        return (
                                            <MenuItem key={item.response_data.name + index + tableMeta.rowIndex} value={item.response_id}>
                                                {item.npg_sync_data.name + " - " + item.response_data.name + " - " + (item.is_active ? "Active" : "Inactive") }
                                            </MenuItem>
                                        );
                                    }else { return; }                                    
                                })}

                             </Select>
                         </FormControl>)
                        }else{
                            return (<div></div>);
                        }
                    }
                }
            }
        ];

        let tagTableDisplay;

        if (this.state.isLoading) {
            tagTableDisplay = <CircularProgress className={classes.progress} />;
        } else if (this.state.organizationTags.length > 0 && this.state.npgvanData.length > 0) {
            tagTableDisplay = (
                <MUIDataTable
                    title={"Manage Tag Data Mapping"}
                    data={this.state.organizationTags}
                    columns={columns}
                    options={options}
                />
            );
        } else {
            tagTableDisplay = (
                <Typography varient="body1">
                    No tags or data have been found. Start by creating a tag under tag management or syncing npgvan data.
                </Typography>
            );
        }


        return (<div>
            <h1>Integration Data Mapping</h1>
            <Grid container>
                <Grid item xs={12}>
                    {tagTableDisplay}
                </Grid>
            </Grid>
        </div>);
    }
}

export default withStyles(styles)(TagVanMapping);