import React from 'react';
import NpgVanIntegration from './NpgVanIntegration';
import Grid from "@material-ui/core/Grid";

class OrganizationIntegrations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }




    render() { 
        return ( <div>
            <Grid container>
                <Grid item xs={12} style={{marginBottom: '20px'}}>
                    <h1>Integrations</h1>
                </Grid>
                <Grid item xs={12}>
                <NpgVanIntegration auth={this.props.auth}></NpgVanIntegration>
                </Grid>
            </Grid>
        </div> );
    }
}
 
export default OrganizationIntegrations;