import React, { Component } from "react";
import Conversation from "../AgentConversations/Conversation";
import GoalCard from "./GoalCard";
import { withRouter } from "react-router-dom";
import ConversationSelect from "../AgentPastConversations/ConversationSelect";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

class AgentGoalSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      isLoading: true,
      group_id: this.props.match.params.group_id,
      agent_id: this.props.match.params.agent_id,
      goals: [],
      selected_goal: null,
      sendMessages: false,
    };
  }

  componentWillMount() {}

  componentDidMount() {
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/conversation/goals", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({ goals: response, isLoading: false });
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  }

  selectGoal = (goal, sendMessages) => {
    this.setState({ selected_goal: goal, sendMessages: sendMessages });
  };

  deselectGoal = () => {
    this.setState({ selected_goal: null });
  };
  closeErrorDialog = () => {
    this.setState({ errorMessage: null });
  };

  render() {
    let { goals, isLoading, selected_goal, agent_id } = this.state;

    let goalCards = goals.map((g, index) => {
      return (
        <GoalCard
          key={g.id + "-" + index}
          goal={g}
          selectGoal={this.selectGoal}
        ></GoalCard>
      );
    });

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (selected_goal && this.state.sendMessages) {
      return (
        <Conversation
          goal={selected_goal}
          deselectGoal={this.deselectGoal}
          agent_id={agent_id}
          auth={this.props.auth}
        />
      );
    } else if (selected_goal && !this.state.sendMessages) {
      return <ConversationSelect goal={selected_goal} auth={this.props.auth} />;
    }

    if (goals.length > 0) {
      return <div>{goalCards}</div>;
    } else {
      return <div>There are no active goals to complete.</div>;
    }

    //return <GoalsSelection goals={goals} selectGoal={this.selectGoal} />;
  }
}

export default withRouter(AgentGoalSelect);
